import { doLogout } from '@/state/reducers/authSlice';

import { changeBalanceAccount } from '@/state/reducers/balanceSlice';

import { getEnvironment } from '@/helpers/environmentHelper';
import history from '@/utils/history';
import { useDisclosure } from '@/utils/hooks/useDisclosure';
import { Fragment } from 'react';
import EnvironmentBadge from '../common/EnvironmentBadge';
import SettingsModal from '../modal/SettingsModal';
import DepositRequestModal from '../modal/WithdrawalModal/DepositRequestModal';
import WithdrawalRequestModal from '../modal/WithdrawalModal/WithdrawalRequestModal';
import DesktopNavbar from './DesktopNavbar';
import MobileDrawer from './MobileDrawer';
import MobileNavbar from './MobileNavbar';
import TraderMobileNavbar from './trader/TraderMobileNavbar';

const depositEnabled = window.config.modules.deposit.enabled;
const withdrawalEnabled = window.config.modules.withdrawal.enabled;

const xplorspot_logo = window.config.logo;
const xplortrader_logo = window.config.trader_logo;

export const onLogout = async (credentials, dispatch) => {
    try {
        if (credentials) await dispatch(doLogout({ credentials }) as any).unwrap();
    } catch (rejectedValueOrSerializedError) {
        //
    }
    history.push('/login');
};

export const onUserChange = async (credentials, dispatch) => {
    if (credentials && getEnvironment() !== 'prod') await dispatch(changeBalanceAccount({ credentials }));
};

export default function Navbar() {
    const deposit = useDisclosure(false);
    const withdrawal = useDisclosure(false);
    const settings = useDisclosure(false);
    const drawer = useDisclosure(false);

    const isXplorSpot = !location.pathname.includes('/trader');

    return (
        <Fragment>
            <div className="flex justify-between items-end bg-brand-background relative h-12 gap-3">
                <div className="flex flex-row h-full items-center xs:items-end gap-3">
                    <img
                        className="block h-auto xs:h-12"
                        src={isXplorSpot ? xplorspot_logo : xplortrader_logo}
                        alt={isXplorSpot ? 'XplorSpot' : 'XplorTrader'}
                    />
                    <EnvironmentBadge />
                </div>

                {isXplorSpot ? (
                    <MobileNavbar drawerDisclosure={drawer} />
                ) : (
                    <TraderMobileNavbar drawerDisclosure={drawer} />
                )}

                <DesktopNavbar
                    depositDisclosure={deposit}
                    withdrawalDisclosure={withdrawal}
                    settingsDisclosure={settings}
                />
            </div>
            <MobileDrawer
                depositDisclosure={deposit}
                withdrawalDisclosure={withdrawal}
                settingsDisclosure={settings}
                drawerDisclosure={drawer}
            />
            {depositEnabled && <DepositRequestModal opened={deposit[0]} handlers={deposit[1]} />}
            {withdrawalEnabled && <WithdrawalRequestModal opened={withdrawal[0]} handlers={withdrawal[1]} />}
            {/* {withdrawalEnabled && <WithdrawalModal opened={withdrawal[0]} handlers={withdrawal[1]} />} */}
            <SettingsModal opened={settings[0]} handlers={settings[1]} />
        </Fragment>
    );
}
