import { FetchingStatus } from '@/model/fetchingStatus';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { RootState } from '../store';

export interface TraderMarket {
    celer: string;
    show: string;
    netdania: string;
}

export interface TraderMarketState {
    status: FetchingStatus;
    watchlists: {
        id: string;
        name: string;
        markets: TraderMarket[];
    }[];
    selectedWatchList?: {
        id: string;
        label: string;
        value: string;
        markets: TraderMarket[];
    };
}

const initialState: TraderMarketState = {
    status: 'idle',
    watchlists: [
        {
            id: uuidv4(),
            name: 'Watchlist',
            markets: [
                { celer: 'BTC/USD', show: 'BTC/USD', netdania: 'BTC/USD' },
                { celer: 'ETH/USD', show: 'ETH/USD', netdania: 'ETH/USD' },
                { celer: 'XRP/USD', show: 'XRP/USD', netdania: 'XRP/USD' },
                { celer: 'EUR/USD', show: 'EUR/USD', netdania: 'EUR/USD' },
                { celer: 'XAU/USD', show: 'XAU/USD', netdania: 'XAU/USD' },
                { celer: 'USD/JPY', show: 'USD/JPY', netdania: 'USD/JPY' },
                { celer: 'GEM/EUR', show: 'GEM/EUR', netdania: 'GEM/EUR' },
                { celer: 'DJI/USD', show: 'DJI/USD', netdania: 'DJI/USD' },
                { celer: 'UKI/GBP', show: 'UKI/GBP', netdania: 'UKI/GBP' }
            ]
        }
    ],
    selectedWatchList: undefined
};

export const traderMarketSlice = createSlice({
    name: 'traderMarket',
    initialState,
    reducers: {
        addWatchList: (state, action: PayloadAction<{ name: string }>) => {
            const newWatchList = {
                id: uuidv4(),
                name: action.payload.name,
                markets: []
            };

            state.watchlists = [...state.watchlists, newWatchList];
            state.selectedWatchList = {
                id: newWatchList.id,
                value: newWatchList.id,
                label: newWatchList.name,
                markets: newWatchList.markets
            };
        },
        editWatchList: (state, action: PayloadAction<{ id: string; name: string }>) => {
            const index = state.watchlists.findIndex((watchlist) => watchlist.id === action.payload.id);
            const watchlist = state.watchlists[index];

            if (watchlist) {
                const updatedWatchList = {
                    id: action.payload.id,
                    name: action.payload.name,
                    markets: watchlist.markets
                };

                state.watchlists[index] = updatedWatchList;
                state.selectedWatchList = {
                    id: updatedWatchList.id,
                    value: updatedWatchList.id,
                    label: updatedWatchList.name,
                    markets: updatedWatchList.markets
                };
            }
        },
        deleteWatchList: (state, action: PayloadAction<{ id: string }>) => {
            state.watchlists = state.watchlists.filter((watchlist) => watchlist.id !== action.payload.id);
            const selected = state.watchlists[0];

            state.selectedWatchList = {
                id: selected.id,
                value: selected.id,
                label: selected.name,
                markets: selected.markets
            };
        },
        arrangeWatchList: (state, action: PayloadAction<{ id: string; markets: TraderMarket[] }>) => {
            const index = state.watchlists.findIndex((watchlist) => watchlist.id === action.payload.id);
            const watchlist = state.watchlists[index];

            if (watchlist) {
                const updatedWatchList = {
                    id: action.payload.id,
                    name: watchlist.name,
                    markets: action.payload.markets
                };

                state.watchlists[index] = updatedWatchList;
                state.selectedWatchList = {
                    id: updatedWatchList.id,
                    value: updatedWatchList.id,
                    label: updatedWatchList.name,
                    markets: updatedWatchList.markets
                };
            }
        },
        setSelectedWatchList: (state, action: PayloadAction<TraderMarketState['selectedWatchList']>) => {
            state.selectedWatchList = action.payload;
        },
        addSubscription: (state, action: PayloadAction<{ id: string; market: TraderMarket }>) => {
            const addedMarket = action.payload.market;
            const index = state.watchlists.findIndex((watchlist) => watchlist.id === action.payload.id);

            if (index !== -1) {
                const updatedMarkets = [...state.watchlists[index].markets, addedMarket];
                state.watchlists[index].markets = updatedMarkets;
                state.selectedWatchList = {
                    id: state.watchlists[index].id,
                    value: state.watchlists[index].id,
                    label: state.watchlists[index].name,
                    markets: updatedMarkets
                };
            }
        },
        removeSubscription: (state, action: PayloadAction<{ id: string; market: TraderMarket }>) => {
            const removeMarket = action.payload.market;
            const index = state.watchlists.findIndex((watchlist) => watchlist.id === action.payload.id);

            if (index !== -1) {
                const updatedMarkets = state.watchlists[index].markets.filter(
                    (market) => market.celer !== removeMarket.celer
                );
                state.watchlists[index].markets = updatedMarkets;
                state.selectedWatchList = {
                    id: state.watchlists[index].id,
                    value: state.watchlists[index].id,
                    label: state.watchlists[index].name,
                    markets: updatedMarkets
                };
            }
        }
    }
});

export const {
    addWatchList,
    editWatchList,
    deleteWatchList,
    arrangeWatchList,
    setSelectedWatchList,
    addSubscription,
    removeSubscription
} = traderMarketSlice.actions;
export const selectCurrentWatchList = (state: RootState) => state.traderMarket.selectedWatchList;
export const selectTraderWatchLists = (state: RootState) => state.traderMarket.watchlists;

export default traderMarketSlice.reducer;
