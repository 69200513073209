import Table from '@/components/table/Table';
import { defaultColumn } from '@/config/config';
import { historyColumns } from '@/helpers/table/historyBlotterTableHelper';
import { OrderHistory } from '@/model/blotters';
import { useAppSelector } from '@/state/hooks';
import { selectCredentials, selectCurrentAccount } from '@/state/reducers/authSlice';
import { selectGeneralSettings } from '@/state/reducers/settingSlice';
import {
    SortingState,
    getCoreRowModel,
    getFacetedMinMaxValues,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable
} from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { useDateFormatter, useLocale } from 'react-aria';
import { mapTradeBlotters } from './TradeBlotterTable';

interface OrderHistoryTableProps {
    orderHistories: OrderHistory[];
    setCSVData: (data: any[]) => void;
}

const OrderHistoryTable = (props: OrderHistoryTableProps) => {
    const { orderHistories, setCSVData } = props;

    const { locale } = useLocale();
    const credentials = useAppSelector(selectCredentials);
    const currentAccount = useAppSelector(selectCurrentAccount);
    const generalSettings = useAppSelector(selectGeneralSettings);

    const formatDate = useDateFormatter();
    const [sorting, setSorting] = useState<SortingState>([{ id: 'time', desc: false }]);

    const isUTC = useMemo(() => generalSettings.timezone.value === 'UTC', [generalSettings]);
    const formattedTrades: any = useMemo(() => {
        return orderHistories
            .filter((trade) => trade.account === currentAccount || trade.account === currentAccount?.slice(0, 36))
            .map((trade) => mapTradeBlotters(trade, locale, credentials, formatDate, isUTC));
    }, [orderHistories, locale, currentAccount, credentials, isUTC]);

    const columns = useMemo(() => historyColumns(generalSettings), [generalSettings]);

    const tableInstance = useReactTable({
        data: formattedTrades,
        columns,
        state: { sorting },
        defaultColumn,
        enableColumnResizing: true,
        columnResizeMode: 'onChange',
        globalFilterFn: 'includesString',
        onSortingChange: (sorting) => setSorting(sorting),
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        getSubRows: (row: any) => row.subRows,
        enableRowSelection: true
    });

    useEffect(() => {
        setCSVData(formattedTrades);
    }, [formattedTrades]);

    return <Table tableInstance={tableInstance} isLoadingTableContent={false} />;
};

export default OrderHistoryTable;
