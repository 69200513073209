import { TradeRow } from '@/components/modules/Blotters/TradeBlotterTable';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import cn from 'classnames';

export const tradeColumns = (formatQty, generalSettings): ColumnDef<TradeRow>[] => {
    return [
        { accessorKey: 'executionTime', header: `Execution Time (${generalSettings.timezone.value})`, size: 130 },
        { accessorKey: 'orderType', header: 'OrderType', size: 80 },
        { accessorKey: 'type', header: 'Type', size: 100 },
        { accessorKey: 'instrument', header: 'Instrument', size: 100 },
        { accessorKey: 'fillPrice', header: 'Fill Price', size: 80 },
        { accessorKey: 'avgFillPrice', header: 'Avg Fill Price', size: 80 },
        { accessorKey: 'side', header: 'Side', size: 60 },
        {
            size: 60,
            accessorKey: 'ccy1',
            header: 'Ccy1',
            cell: ({ row, getValue }: CellContext<TradeRow, any>) => {
                const value = row.original.qty1;
                const ccy = getValue();

                return (
                    <div
                        className={cn('text-sm', {
                            'text-brand-primary': value > 0,
                            'text-brand-red': value < 0
                        })}>
                        {ccy}
                    </div>
                );
            }
        },
        {
            size: 80,
            accessorKey: 'qty1',
            header: 'Qty1',
            cell: ({ row, getValue }: CellContext<TradeRow, any>) => {
                const value = getValue();

                return (
                    <div
                        className={cn('text-sm', {
                            'text-brand-primary': value > 0,
                            'text-brand-red': value < 0
                        })}>
                        {isNaN(value) ? '-' : formatQty.format(value)}
                    </div>
                );
            }
        },
        {
            size: 60,
            accessorKey: 'ccy2',
            header: 'Ccy2',
            cell: ({ row, getValue }: CellContext<TradeRow, any>) => {
                const value = row.original.qty2;
                const ccy = getValue();

                return (
                    <div
                        className={cn('text-sm', {
                            'text-brand-primary': value > 0,
                            'text-brand-red': value < 0
                        })}>
                        {ccy}
                    </div>
                );
            }
        },
        {
            size: 80,
            accessorKey: 'qty2',
            header: 'Qty2',
            cell: ({ row, getValue }: CellContext<TradeRow, any>) => {
                const value = getValue();

                return (
                    <div
                        className={cn('text-sm', {
                            'text-brand-primary': value > 0,
                            'text-brand-red': value < 0
                        })}>
                        {isNaN(value) ? '-' : formatQty.format(value)}
                    </div>
                );
            }
        },
        { accessorKey: 'orderId', header: 'Order Id', size: 160 },
        { accessorKey: 'executionId', header: 'Execution Id', size: 160 },
        { accessorKey: 'fillNo', header: 'Fill No', size: 80 },
        { accessorKey: 'account', header: 'Account', size: 100 },
        { accessorKey: 'user', header: 'User', size: 100 }
    ];
};
