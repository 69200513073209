/* eslint-disable */
import Long from 'long';
import _m0 from 'protobufjs/minimal';
import { AssetType } from '../enums/AssetTypeProto';
import { MarketDataActionType } from '../enums/MarketDataActionTypeProto';
import { MarketDataBookType } from '../enums/MarketDataBookTypeProto';
import { MarketDataEntryType } from '../enums/MarketDataEntryTypeProto';
import { MarketDataQuoteType } from '../enums/MarketDataQuoteTypeProto';
import { MarketDataRequestRejectReason } from '../enums/MarketDataRequestRejectReasonProto';
import { MarketDataTradingStatus } from '../enums/MarketDataTradingStatusProto';
import { PriceType } from '../enums/PriceTypeProto';
import { ProductType } from '../enums/ProductTypeProto';

export const protobufPackage = 'com.celertech.marketdata.api.price';

export interface MarketDataFullSnapshotDownstreamEvent {
    assetType: AssetType;
    exchangeCode: string;
    securityCode: string;
    securityId: string;
    totalTradedVolume: number;
    marketDataBookType: MarketDataBookType;
    snapshotTimestampInMillis: string;
    partyId: string;
    marketDataPriceSnapshotLevel: MarketDataFullSnapshotDownstreamEvent_MarketDataPriceSnapshotLevel[];
    requestedByUser: string;
    marketDataRequestId: string;
    productType: ProductType;
    maturityDate: string;
    priceType: PriceType;
}

export interface MarketDataFullSnapshotDownstreamEvent_MarketDataPriceSnapshotLevel {
    marketDataEntryType: MarketDataEntryType;
    entryId: string;
    entryPrice: number;
    entrySize: number;
    pricePosition: number;
    quoteEntryId: string;
    entryOriginator: string;
    settlementType: string;
    settlementDate: string;
    marketDataQuoteType: MarketDataQuoteType;
    spotRate: number;
    forwardPoints: number;
    rateSource: string;
    fixingDate: string;
    rawEntryPrice: number;
    rawSpotPrice: number;
    rawForwardPt: number;
    farEntryPrice: number;
    farSpotPrice: number;
    farForwardPt: number;
    farSettlementType: string;
    farSettlementDate: string;
    farEntryOriginator: string;
}

export interface MarketDataIncrementalDownstreamEvent {
    assetType: AssetType;
    /** FIXME: To be correctly modelled from FIX, exchangeCode, securityCode, securityId should be in the price snapshot level as it mimics the MarketDataIncrementalGroup */
    exchangeCode: string;
    securityCode: string;
    securityId: string;
    totalTradedVolume: number;
    snapshotTimestampInMillis: string;
    marketDataBookType: MarketDataBookType;
    partyId: string;
    marketDataPriceSnapshotLevel: MarketDataIncrementalDownstreamEvent_MarketDataPriceSnapshotLevel[];
    checkpointTimestamp: string[];
    requestedByUser: string;
    marketDataRequestId: string;
    productType: ProductType;
}

export interface MarketDataIncrementalDownstreamEvent_MarketDataPriceSnapshotLevel {
    marketDataActionType: MarketDataActionType;
    marketDataEntryType: MarketDataEntryType;
    entryId: string;
    entryPrice: number;
    entrySize: number;
    pricePosition: number;
    quoteEntryId: string;
    entryOriginator: string;
    settlementType: string;
    settlementDate: string;
    marketDataQuoteType: MarketDataQuoteType;
    spotRate: number;
    forwardPoints: number;
}

export interface MarketDataSecurityStatusDownstreamEvent {
    exchangeCode: string;
    securityCode: string;
    status: MarketDataTradingStatus;
    updated: string;
}

export interface MarketDataPriceTickerDownstreamEvent {
    assetType: AssetType;
    exchangeCode: string;
    securityCode: string;
    securityId: string;
    productType: ProductType;
    sourceMarket: string;
    totalVolumeTraded: number;
    totalVolumeForSymbolTraded: number;
    lastPrice: number;
    lastQty: number;
    receivedTime: string;
}

export interface MarketDataDestinationCommandDownstreamEvent {
    operation: MarketDataDestinationCommandDownstreamEvent_Operation;
    marketDataDestinationKey: string;
    exchangeCode: string;
}

export enum MarketDataDestinationCommandDownstreamEvent_Operation {
    START = 0,
    STOP = 1,
    EOD = 2,
    UNRECOGNIZED = -1
}

export interface MarketDataRequestRejectDownstreamEvent {
    marketDataRequestId: string;
    marketDataRequestRejectReason: MarketDataRequestRejectReason;
    text: string;
    assetType: AssetType;
    exchangeCode: string;
    securityId: string;
    securityCode: string;
    requestedByUser: string;
    productType: ProductType;
}

function createBaseMarketDataFullSnapshotDownstreamEvent(): MarketDataFullSnapshotDownstreamEvent {
    return {
        assetType: 1,
        exchangeCode: '',
        securityCode: '',
        securityId: '',
        totalTradedVolume: 0,
        marketDataBookType: 1,
        snapshotTimestampInMillis: '0',
        partyId: '',
        marketDataPriceSnapshotLevel: [],
        requestedByUser: '',
        marketDataRequestId: '',
        productType: 1,
        maturityDate: '',
        priceType: 1
    };
}

export const MarketDataFullSnapshotDownstreamEvent = {
    encode(message: MarketDataFullSnapshotDownstreamEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.assetType !== 1) {
            writer.uint32(8).int32(message.assetType);
        }
        if (message.exchangeCode !== '') {
            writer.uint32(18).string(message.exchangeCode);
        }
        if (message.securityCode !== '') {
            writer.uint32(26).string(message.securityCode);
        }
        if (message.securityId !== '') {
            writer.uint32(34).string(message.securityId);
        }
        if (message.totalTradedVolume !== 0) {
            writer.uint32(41).double(message.totalTradedVolume);
        }
        if (message.marketDataBookType !== 1) {
            writer.uint32(48).int32(message.marketDataBookType);
        }
        if (message.snapshotTimestampInMillis !== '0') {
            writer.uint32(56).int64(message.snapshotTimestampInMillis);
        }
        if (message.partyId !== '') {
            writer.uint32(66).string(message.partyId);
        }
        for (const v of message.marketDataPriceSnapshotLevel) {
            MarketDataFullSnapshotDownstreamEvent_MarketDataPriceSnapshotLevel.encode(
                v!,
                writer.uint32(74).fork()
            ).ldelim();
        }
        if (message.requestedByUser !== '') {
            writer.uint32(90).string(message.requestedByUser);
        }
        if (message.marketDataRequestId !== '') {
            writer.uint32(98).string(message.marketDataRequestId);
        }
        if (message.productType !== 1) {
            writer.uint32(104).int32(message.productType);
        }
        if (message.maturityDate !== '') {
            writer.uint32(114).string(message.maturityDate);
        }
        if (message.priceType !== 1) {
            writer.uint32(120).int32(message.priceType);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): MarketDataFullSnapshotDownstreamEvent {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMarketDataFullSnapshotDownstreamEvent();
        try {
            while (reader.pos < end) {
                const tag = reader.uint32();
                switch (tag >>> 3) {
                    case 1:
                        message.assetType = reader.int32() as any;
                        break;
                    case 2:
                        message.exchangeCode = reader.string();
                        break;
                    case 3:
                        message.securityCode = reader.string();
                        break;
                    case 4:
                        message.securityId = reader.string();
                        break;
                    case 5:
                        message.totalTradedVolume = reader.double();
                        break;
                    case 6:
                        message.marketDataBookType = reader.int32() as any;
                        break;
                    case 7:
                        message.snapshotTimestampInMillis = longToString(reader.int64() as Long);
                        break;
                    case 8:
                        message.partyId = reader.string();
                        break;
                    case 9:
                        message.marketDataPriceSnapshotLevel.push(
                            MarketDataFullSnapshotDownstreamEvent_MarketDataPriceSnapshotLevel.decode(
                                reader,
                                reader.uint32()
                            )
                        );
                        break;
                    case 11:
                        message.requestedByUser = reader.string();
                        break;
                    case 12:
                        message.marketDataRequestId = reader.string();
                        break;
                    case 13:
                        message.productType = reader.int32() as any;
                        break;
                    case 14:
                        message.maturityDate = reader.string();
                        break;
                    case 15:
                        message.priceType = reader.int32() as any;
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                }
            }
            return message;
        } catch (e) {
            console.error(e, 'Error decoding MarketDataFullSnapshotDownstreamEvent, returning empty object...');
            return createBaseMarketDataFullSnapshotDownstreamEvent();
        }
    },

    fromPartial(object: DeepPartial<MarketDataFullSnapshotDownstreamEvent>): MarketDataFullSnapshotDownstreamEvent {
        const message = createBaseMarketDataFullSnapshotDownstreamEvent();
        message.assetType = object.assetType ?? 1;
        message.exchangeCode = object.exchangeCode ?? '';
        message.securityCode = object.securityCode ?? '';
        message.securityId = object.securityId ?? '';
        message.totalTradedVolume = object.totalTradedVolume ?? 0;
        message.marketDataBookType = object.marketDataBookType ?? 1;
        message.snapshotTimestampInMillis = object.snapshotTimestampInMillis ?? '0';
        message.partyId = object.partyId ?? '';
        message.marketDataPriceSnapshotLevel =
            object.marketDataPriceSnapshotLevel?.map((e) =>
                MarketDataFullSnapshotDownstreamEvent_MarketDataPriceSnapshotLevel.fromPartial(e)
            ) || [];
        message.requestedByUser = object.requestedByUser ?? '';
        message.marketDataRequestId = object.marketDataRequestId ?? '';
        message.productType = object.productType ?? 1;
        message.maturityDate = object.maturityDate ?? '';
        message.priceType = object.priceType ?? 1;
        return message;
    }
};

function createBaseMarketDataFullSnapshotDownstreamEvent_MarketDataPriceSnapshotLevel(): MarketDataFullSnapshotDownstreamEvent_MarketDataPriceSnapshotLevel {
    return {
        marketDataEntryType: 1,
        entryId: '',
        entryPrice: 0,
        entrySize: 0,
        pricePosition: 0,
        quoteEntryId: '',
        entryOriginator: '',
        settlementType: '',
        settlementDate: '',
        marketDataQuoteType: 1,
        spotRate: 0,
        forwardPoints: 0,
        rateSource: '',
        fixingDate: '',
        rawEntryPrice: 0,
        rawSpotPrice: 0,
        rawForwardPt: 0,
        farEntryPrice: 0,
        farSpotPrice: 0,
        farForwardPt: 0,
        farSettlementType: '',
        farSettlementDate: '',
        farEntryOriginator: ''
    };
}

export const MarketDataFullSnapshotDownstreamEvent_MarketDataPriceSnapshotLevel = {
    encode(
        message: MarketDataFullSnapshotDownstreamEvent_MarketDataPriceSnapshotLevel,
        writer: _m0.Writer = _m0.Writer.create()
    ): _m0.Writer {
        if (message.marketDataEntryType !== 1) {
            writer.uint32(8).int32(message.marketDataEntryType);
        }
        if (message.entryId !== '') {
            writer.uint32(18).string(message.entryId);
        }
        if (message.entryPrice !== 0) {
            writer.uint32(25).double(message.entryPrice);
        }
        if (message.entrySize !== 0) {
            writer.uint32(33).double(message.entrySize);
        }
        if (message.pricePosition !== 0) {
            writer.uint32(40).int32(message.pricePosition);
        }
        if (message.quoteEntryId !== '') {
            writer.uint32(50).string(message.quoteEntryId);
        }
        if (message.entryOriginator !== '') {
            writer.uint32(58).string(message.entryOriginator);
        }
        if (message.settlementType !== '') {
            writer.uint32(66).string(message.settlementType);
        }
        if (message.settlementDate !== '') {
            writer.uint32(74).string(message.settlementDate);
        }
        if (message.marketDataQuoteType !== 1) {
            writer.uint32(80).int32(message.marketDataQuoteType);
        }
        if (message.spotRate !== 0) {
            writer.uint32(89).double(message.spotRate);
        }
        if (message.forwardPoints !== 0) {
            writer.uint32(97).double(message.forwardPoints);
        }
        if (message.rateSource !== '') {
            writer.uint32(106).string(message.rateSource);
        }
        if (message.fixingDate !== '') {
            writer.uint32(114).string(message.fixingDate);
        }
        if (message.rawEntryPrice !== 0) {
            writer.uint32(121).double(message.rawEntryPrice);
        }
        if (message.rawSpotPrice !== 0) {
            writer.uint32(129).double(message.rawSpotPrice);
        }
        if (message.rawForwardPt !== 0) {
            writer.uint32(137).double(message.rawForwardPt);
        }
        if (message.farEntryPrice !== 0) {
            writer.uint32(145).double(message.farEntryPrice);
        }
        if (message.farSpotPrice !== 0) {
            writer.uint32(153).double(message.farSpotPrice);
        }
        if (message.farForwardPt !== 0) {
            writer.uint32(161).double(message.farForwardPt);
        }
        if (message.farSettlementType !== '') {
            writer.uint32(170).string(message.farSettlementType);
        }
        if (message.farSettlementDate !== '') {
            writer.uint32(178).string(message.farSettlementDate);
        }
        if (message.farEntryOriginator !== '') {
            writer.uint32(194).string(message.farEntryOriginator);
        }
        return writer;
    },

    decode(
        input: _m0.Reader | Uint8Array,
        length?: number
    ): MarketDataFullSnapshotDownstreamEvent_MarketDataPriceSnapshotLevel {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMarketDataFullSnapshotDownstreamEvent_MarketDataPriceSnapshotLevel();
        try {
            while (reader.pos < end) {
                const tag = reader.uint32();
                switch (tag >>> 3) {
                    case 1:
                        message.marketDataEntryType = reader.int32() as any;
                        break;
                    case 2:
                        message.entryId = reader.string();
                        break;
                    case 3:
                        message.entryPrice = reader.double();
                        break;
                    case 4:
                        message.entrySize = reader.double();
                        break;
                    case 5:
                        message.pricePosition = reader.int32();
                        break;
                    case 6:
                        message.quoteEntryId = reader.string();
                        break;
                    case 7:
                        message.entryOriginator = reader.string();
                        break;
                    case 8:
                        message.settlementType = reader.string();
                        break;
                    case 9:
                        message.settlementDate = reader.string();
                        break;
                    case 10:
                        message.marketDataQuoteType = reader.int32() as any;
                        break;
                    case 11:
                        message.spotRate = reader.double();
                        break;
                    case 12:
                        message.forwardPoints = reader.double();
                        break;
                    case 13:
                        message.rateSource = reader.string();
                        break;
                    case 14:
                        message.fixingDate = reader.string();
                        break;
                    case 15:
                        message.rawEntryPrice = reader.double();
                        break;
                    case 16:
                        message.rawSpotPrice = reader.double();
                        break;
                    case 17:
                        message.rawForwardPt = reader.double();
                        break;
                    case 18:
                        message.farEntryPrice = reader.double();
                        break;
                    case 19:
                        message.farSpotPrice = reader.double();
                        break;
                    case 20:
                        message.farForwardPt = reader.double();
                        break;
                    case 21:
                        message.farSettlementType = reader.string();
                        break;
                    case 22:
                        message.farSettlementDate = reader.string();
                        break;
                    case 24:
                        message.farEntryOriginator = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                }
            }
            return message;
        } catch (e) {
            console.error(
                e,
                'Error decoding MarketDataFullSnapshotDownstreamEvent_MarketDataPriceSnapshotLevel, returning empty object...'
            );
            return createBaseMarketDataFullSnapshotDownstreamEvent_MarketDataPriceSnapshotLevel();
        }
    },

    fromPartial(
        object: DeepPartial<MarketDataFullSnapshotDownstreamEvent_MarketDataPriceSnapshotLevel>
    ): MarketDataFullSnapshotDownstreamEvent_MarketDataPriceSnapshotLevel {
        const message = createBaseMarketDataFullSnapshotDownstreamEvent_MarketDataPriceSnapshotLevel();
        message.marketDataEntryType = object.marketDataEntryType ?? 1;
        message.entryId = object.entryId ?? '';
        message.entryPrice = object.entryPrice ?? 0;
        message.entrySize = object.entrySize ?? 0;
        message.pricePosition = object.pricePosition ?? 0;
        message.quoteEntryId = object.quoteEntryId ?? '';
        message.entryOriginator = object.entryOriginator ?? '';
        message.settlementType = object.settlementType ?? '';
        message.settlementDate = object.settlementDate ?? '';
        message.marketDataQuoteType = object.marketDataQuoteType ?? 1;
        message.spotRate = object.spotRate ?? 0;
        message.forwardPoints = object.forwardPoints ?? 0;
        message.rateSource = object.rateSource ?? '';
        message.fixingDate = object.fixingDate ?? '';
        message.rawEntryPrice = object.rawEntryPrice ?? 0;
        message.rawSpotPrice = object.rawSpotPrice ?? 0;
        message.rawForwardPt = object.rawForwardPt ?? 0;
        message.farEntryPrice = object.farEntryPrice ?? 0;
        message.farSpotPrice = object.farSpotPrice ?? 0;
        message.farForwardPt = object.farForwardPt ?? 0;
        message.farSettlementType = object.farSettlementType ?? '';
        message.farSettlementDate = object.farSettlementDate ?? '';
        message.farEntryOriginator = object.farEntryOriginator ?? '';
        return message;
    }
};

function createBaseMarketDataIncrementalDownstreamEvent(): MarketDataIncrementalDownstreamEvent {
    return {
        assetType: 1,
        exchangeCode: '',
        securityCode: '',
        securityId: '',
        totalTradedVolume: 0,
        snapshotTimestampInMillis: '0',
        marketDataBookType: 1,
        partyId: '',
        marketDataPriceSnapshotLevel: [],
        checkpointTimestamp: [],
        requestedByUser: '',
        marketDataRequestId: '',
        productType: 1
    };
}

export const MarketDataIncrementalDownstreamEvent = {
    encode(message: MarketDataIncrementalDownstreamEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.assetType !== 1) {
            writer.uint32(8).int32(message.assetType);
        }
        if (message.exchangeCode !== '') {
            writer.uint32(18).string(message.exchangeCode);
        }
        if (message.securityCode !== '') {
            writer.uint32(26).string(message.securityCode);
        }
        if (message.securityId !== '') {
            writer.uint32(34).string(message.securityId);
        }
        if (message.totalTradedVolume !== 0) {
            writer.uint32(41).double(message.totalTradedVolume);
        }
        if (message.snapshotTimestampInMillis !== '0') {
            writer.uint32(48).int64(message.snapshotTimestampInMillis);
        }
        if (message.marketDataBookType !== 1) {
            writer.uint32(56).int32(message.marketDataBookType);
        }
        if (message.partyId !== '') {
            writer.uint32(66).string(message.partyId);
        }
        for (const v of message.marketDataPriceSnapshotLevel) {
            MarketDataIncrementalDownstreamEvent_MarketDataPriceSnapshotLevel.encode(
                v!,
                writer.uint32(74).fork()
            ).ldelim();
        }
        writer.uint32(82).fork();
        for (const v of message.checkpointTimestamp) {
            writer.int64(v);
        }
        writer.ldelim();
        if (message.requestedByUser !== '') {
            writer.uint32(90).string(message.requestedByUser);
        }
        if (message.marketDataRequestId !== '') {
            writer.uint32(98).string(message.marketDataRequestId);
        }
        if (message.productType !== 1) {
            writer.uint32(104).int32(message.productType);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): MarketDataIncrementalDownstreamEvent {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMarketDataIncrementalDownstreamEvent();
        try {
            while (reader.pos < end) {
                const tag = reader.uint32();
                switch (tag >>> 3) {
                    case 1:
                        message.assetType = reader.int32() as any;
                        break;
                    case 2:
                        message.exchangeCode = reader.string();
                        break;
                    case 3:
                        message.securityCode = reader.string();
                        break;
                    case 4:
                        message.securityId = reader.string();
                        break;
                    case 5:
                        message.totalTradedVolume = reader.double();
                        break;
                    case 6:
                        message.snapshotTimestampInMillis = longToString(reader.int64() as Long);
                        break;
                    case 7:
                        message.marketDataBookType = reader.int32() as any;
                        break;
                    case 8:
                        message.partyId = reader.string();
                        break;
                    case 9:
                        message.marketDataPriceSnapshotLevel.push(
                            MarketDataIncrementalDownstreamEvent_MarketDataPriceSnapshotLevel.decode(
                                reader,
                                reader.uint32()
                            )
                        );
                        break;
                    case 10:
                        if ((tag & 7) === 2) {
                            const end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2) {
                                message.checkpointTimestamp.push(longToString(reader.int64() as Long));
                            }
                        } else {
                            message.checkpointTimestamp.push(longToString(reader.int64() as Long));
                        }
                        break;
                    case 11:
                        message.requestedByUser = reader.string();
                        break;
                    case 12:
                        message.marketDataRequestId = reader.string();
                        break;
                    case 13:
                        message.productType = reader.int32() as any;
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                }
            }
            return message;
        } catch (e) {
            console.error(e, 'Error decoding MarketDataIncrementalDownstreamEvent, returning empty object...');
            return createBaseMarketDataIncrementalDownstreamEvent();
        }
    },

    fromPartial(object: DeepPartial<MarketDataIncrementalDownstreamEvent>): MarketDataIncrementalDownstreamEvent {
        const message = createBaseMarketDataIncrementalDownstreamEvent();
        message.assetType = object.assetType ?? 1;
        message.exchangeCode = object.exchangeCode ?? '';
        message.securityCode = object.securityCode ?? '';
        message.securityId = object.securityId ?? '';
        message.totalTradedVolume = object.totalTradedVolume ?? 0;
        message.snapshotTimestampInMillis = object.snapshotTimestampInMillis ?? '0';
        message.marketDataBookType = object.marketDataBookType ?? 1;
        message.partyId = object.partyId ?? '';
        message.marketDataPriceSnapshotLevel =
            object.marketDataPriceSnapshotLevel?.map((e) =>
                MarketDataIncrementalDownstreamEvent_MarketDataPriceSnapshotLevel.fromPartial(e)
            ) || [];
        message.checkpointTimestamp = object.checkpointTimestamp?.map((e) => e) || [];
        message.requestedByUser = object.requestedByUser ?? '';
        message.marketDataRequestId = object.marketDataRequestId ?? '';
        message.productType = object.productType ?? 1;
        return message;
    }
};

function createBaseMarketDataIncrementalDownstreamEvent_MarketDataPriceSnapshotLevel(): MarketDataIncrementalDownstreamEvent_MarketDataPriceSnapshotLevel {
    return {
        marketDataActionType: 1,
        marketDataEntryType: 1,
        entryId: '',
        entryPrice: 0,
        entrySize: 0,
        pricePosition: 0,
        quoteEntryId: '',
        entryOriginator: '',
        settlementType: '',
        settlementDate: '',
        marketDataQuoteType: 1,
        spotRate: 0,
        forwardPoints: 0
    };
}

export const MarketDataIncrementalDownstreamEvent_MarketDataPriceSnapshotLevel = {
    encode(
        message: MarketDataIncrementalDownstreamEvent_MarketDataPriceSnapshotLevel,
        writer: _m0.Writer = _m0.Writer.create()
    ): _m0.Writer {
        if (message.marketDataActionType !== 1) {
            writer.uint32(8).int32(message.marketDataActionType);
        }
        if (message.marketDataEntryType !== 1) {
            writer.uint32(16).int32(message.marketDataEntryType);
        }
        if (message.entryId !== '') {
            writer.uint32(26).string(message.entryId);
        }
        if (message.entryPrice !== 0) {
            writer.uint32(33).double(message.entryPrice);
        }
        if (message.entrySize !== 0) {
            writer.uint32(41).double(message.entrySize);
        }
        if (message.pricePosition !== 0) {
            writer.uint32(48).int32(message.pricePosition);
        }
        if (message.quoteEntryId !== '') {
            writer.uint32(58).string(message.quoteEntryId);
        }
        if (message.entryOriginator !== '') {
            writer.uint32(66).string(message.entryOriginator);
        }
        if (message.settlementType !== '') {
            writer.uint32(74).string(message.settlementType);
        }
        if (message.settlementDate !== '') {
            writer.uint32(82).string(message.settlementDate);
        }
        if (message.marketDataQuoteType !== 1) {
            writer.uint32(88).int32(message.marketDataQuoteType);
        }
        if (message.spotRate !== 0) {
            writer.uint32(97).double(message.spotRate);
        }
        if (message.forwardPoints !== 0) {
            writer.uint32(105).double(message.forwardPoints);
        }
        return writer;
    },

    decode(
        input: _m0.Reader | Uint8Array,
        length?: number
    ): MarketDataIncrementalDownstreamEvent_MarketDataPriceSnapshotLevel {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMarketDataIncrementalDownstreamEvent_MarketDataPriceSnapshotLevel();
        try {
            while (reader.pos < end) {
                const tag = reader.uint32();
                switch (tag >>> 3) {
                    case 1:
                        message.marketDataActionType = reader.int32() as any;
                        break;
                    case 2:
                        message.marketDataEntryType = reader.int32() as any;
                        break;
                    case 3:
                        message.entryId = reader.string();
                        break;
                    case 4:
                        message.entryPrice = reader.double();
                        break;
                    case 5:
                        message.entrySize = reader.double();
                        break;
                    case 6:
                        message.pricePosition = reader.int32();
                        break;
                    case 7:
                        message.quoteEntryId = reader.string();
                        break;
                    case 8:
                        message.entryOriginator = reader.string();
                        break;
                    case 9:
                        message.settlementType = reader.string();
                        break;
                    case 10:
                        message.settlementDate = reader.string();
                        break;
                    case 11:
                        message.marketDataQuoteType = reader.int32() as any;
                        break;
                    case 12:
                        message.spotRate = reader.double();
                        break;
                    case 13:
                        message.forwardPoints = reader.double();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                }
            }
            return message;
        } catch (e) {
            console.error(
                e,
                'Error decoding MarketDataIncrementalDownstreamEvent_MarketDataPriceSnapshotLevel, returning empty object...'
            );
            return createBaseMarketDataIncrementalDownstreamEvent_MarketDataPriceSnapshotLevel();
        }
    },

    fromPartial(
        object: DeepPartial<MarketDataIncrementalDownstreamEvent_MarketDataPriceSnapshotLevel>
    ): MarketDataIncrementalDownstreamEvent_MarketDataPriceSnapshotLevel {
        const message = createBaseMarketDataIncrementalDownstreamEvent_MarketDataPriceSnapshotLevel();
        message.marketDataActionType = object.marketDataActionType ?? 1;
        message.marketDataEntryType = object.marketDataEntryType ?? 1;
        message.entryId = object.entryId ?? '';
        message.entryPrice = object.entryPrice ?? 0;
        message.entrySize = object.entrySize ?? 0;
        message.pricePosition = object.pricePosition ?? 0;
        message.quoteEntryId = object.quoteEntryId ?? '';
        message.entryOriginator = object.entryOriginator ?? '';
        message.settlementType = object.settlementType ?? '';
        message.settlementDate = object.settlementDate ?? '';
        message.marketDataQuoteType = object.marketDataQuoteType ?? 1;
        message.spotRate = object.spotRate ?? 0;
        message.forwardPoints = object.forwardPoints ?? 0;
        return message;
    }
};

function createBaseMarketDataSecurityStatusDownstreamEvent(): MarketDataSecurityStatusDownstreamEvent {
    return { exchangeCode: '', securityCode: '', status: 1, updated: '' };
}

export const MarketDataSecurityStatusDownstreamEvent = {
    encode(message: MarketDataSecurityStatusDownstreamEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.exchangeCode !== '') {
            writer.uint32(10).string(message.exchangeCode);
        }
        if (message.securityCode !== '') {
            writer.uint32(18).string(message.securityCode);
        }
        if (message.status !== 1) {
            writer.uint32(24).int32(message.status);
        }
        if (message.updated !== '') {
            writer.uint32(34).string(message.updated);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): MarketDataSecurityStatusDownstreamEvent {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMarketDataSecurityStatusDownstreamEvent();
        try {
            while (reader.pos < end) {
                const tag = reader.uint32();
                switch (tag >>> 3) {
                    case 1:
                        message.exchangeCode = reader.string();
                        break;
                    case 2:
                        message.securityCode = reader.string();
                        break;
                    case 3:
                        message.status = reader.int32() as any;
                        break;
                    case 4:
                        message.updated = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                }
            }
            return message;
        } catch (e) {
            console.error(e, 'Error decoding MarketDataSecurityStatusDownstreamEvent, returning empty object...');
            return createBaseMarketDataSecurityStatusDownstreamEvent();
        }
    },

    fromPartial(object: DeepPartial<MarketDataSecurityStatusDownstreamEvent>): MarketDataSecurityStatusDownstreamEvent {
        const message = createBaseMarketDataSecurityStatusDownstreamEvent();
        message.exchangeCode = object.exchangeCode ?? '';
        message.securityCode = object.securityCode ?? '';
        message.status = object.status ?? 1;
        message.updated = object.updated ?? '';
        return message;
    }
};

function createBaseMarketDataPriceTickerDownstreamEvent(): MarketDataPriceTickerDownstreamEvent {
    return {
        assetType: 1,
        exchangeCode: '',
        securityCode: '',
        securityId: '',
        productType: 1,
        sourceMarket: '',
        totalVolumeTraded: 0,
        totalVolumeForSymbolTraded: 0,
        lastPrice: 0,
        lastQty: 0,
        receivedTime: '0'
    };
}

export const MarketDataPriceTickerDownstreamEvent = {
    encode(message: MarketDataPriceTickerDownstreamEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.assetType !== 1) {
            writer.uint32(8).int32(message.assetType);
        }
        if (message.exchangeCode !== '') {
            writer.uint32(18).string(message.exchangeCode);
        }
        if (message.securityCode !== '') {
            writer.uint32(26).string(message.securityCode);
        }
        if (message.securityId !== '') {
            writer.uint32(34).string(message.securityId);
        }
        if (message.productType !== 1) {
            writer.uint32(40).int32(message.productType);
        }
        if (message.sourceMarket !== '') {
            writer.uint32(50).string(message.sourceMarket);
        }
        if (message.totalVolumeTraded !== 0) {
            writer.uint32(57).double(message.totalVolumeTraded);
        }
        if (message.totalVolumeForSymbolTraded !== 0) {
            writer.uint32(65).double(message.totalVolumeForSymbolTraded);
        }
        if (message.lastPrice !== 0) {
            writer.uint32(73).double(message.lastPrice);
        }
        if (message.lastQty !== 0) {
            writer.uint32(81).double(message.lastQty);
        }
        if (message.receivedTime !== '0') {
            writer.uint32(88).int64(message.receivedTime);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): MarketDataPriceTickerDownstreamEvent {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMarketDataPriceTickerDownstreamEvent();
        try {
            while (reader.pos < end) {
                const tag = reader.uint32();
                switch (tag >>> 3) {
                    case 1:
                        message.assetType = reader.int32() as any;
                        break;
                    case 2:
                        message.exchangeCode = reader.string();
                        break;
                    case 3:
                        message.securityCode = reader.string();
                        break;
                    case 4:
                        message.securityId = reader.string();
                        break;
                    case 5:
                        message.productType = reader.int32() as any;
                        break;
                    case 6:
                        message.sourceMarket = reader.string();
                        break;
                    case 7:
                        message.totalVolumeTraded = reader.double();
                        break;
                    case 8:
                        message.totalVolumeForSymbolTraded = reader.double();
                        break;
                    case 9:
                        message.lastPrice = reader.double();
                        break;
                    case 10:
                        message.lastQty = reader.double();
                        break;
                    case 11:
                        message.receivedTime = longToString(reader.int64() as Long);
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                }
            }
            return message;
        } catch (e) {
            console.error(e, 'Error decoding MarketDataPriceTickerDownstreamEvent, returning empty object...');
            return createBaseMarketDataPriceTickerDownstreamEvent();
        }
    },

    fromPartial(object: DeepPartial<MarketDataPriceTickerDownstreamEvent>): MarketDataPriceTickerDownstreamEvent {
        const message = createBaseMarketDataPriceTickerDownstreamEvent();
        message.assetType = object.assetType ?? 1;
        message.exchangeCode = object.exchangeCode ?? '';
        message.securityCode = object.securityCode ?? '';
        message.securityId = object.securityId ?? '';
        message.productType = object.productType ?? 1;
        message.sourceMarket = object.sourceMarket ?? '';
        message.totalVolumeTraded = object.totalVolumeTraded ?? 0;
        message.totalVolumeForSymbolTraded = object.totalVolumeForSymbolTraded ?? 0;
        message.lastPrice = object.lastPrice ?? 0;
        message.lastQty = object.lastQty ?? 0;
        message.receivedTime = object.receivedTime ?? '0';
        return message;
    }
};

function createBaseMarketDataDestinationCommandDownstreamEvent(): MarketDataDestinationCommandDownstreamEvent {
    return { operation: 0, marketDataDestinationKey: '', exchangeCode: '' };
}

export const MarketDataDestinationCommandDownstreamEvent = {
    encode(message: MarketDataDestinationCommandDownstreamEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.operation !== 0) {
            writer.uint32(16).int32(message.operation);
        }
        if (message.marketDataDestinationKey !== '') {
            writer.uint32(26).string(message.marketDataDestinationKey);
        }
        if (message.exchangeCode !== '') {
            writer.uint32(34).string(message.exchangeCode);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): MarketDataDestinationCommandDownstreamEvent {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMarketDataDestinationCommandDownstreamEvent();
        try {
            while (reader.pos < end) {
                const tag = reader.uint32();
                switch (tag >>> 3) {
                    case 2:
                        message.operation = reader.int32() as any;
                        break;
                    case 3:
                        message.marketDataDestinationKey = reader.string();
                        break;
                    case 4:
                        message.exchangeCode = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                }
            }
            return message;
        } catch (e) {
            console.error(e, 'Error decoding MarketDataDestinationCommandDownstreamEvent, returning empty object...');
            return createBaseMarketDataDestinationCommandDownstreamEvent();
        }
    },

    fromPartial(
        object: DeepPartial<MarketDataDestinationCommandDownstreamEvent>
    ): MarketDataDestinationCommandDownstreamEvent {
        const message = createBaseMarketDataDestinationCommandDownstreamEvent();
        message.operation = object.operation ?? 0;
        message.marketDataDestinationKey = object.marketDataDestinationKey ?? '';
        message.exchangeCode = object.exchangeCode ?? '';
        return message;
    }
};

function createBaseMarketDataRequestRejectDownstreamEvent(): MarketDataRequestRejectDownstreamEvent {
    return {
        marketDataRequestId: '',
        marketDataRequestRejectReason: 1,
        text: '',
        assetType: 1,
        exchangeCode: '',
        securityId: '',
        securityCode: '',
        requestedByUser: '',
        productType: 1
    };
}

export const MarketDataRequestRejectDownstreamEvent = {
    encode(message: MarketDataRequestRejectDownstreamEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.marketDataRequestId !== '') {
            writer.uint32(10).string(message.marketDataRequestId);
        }
        if (message.marketDataRequestRejectReason !== 1) {
            writer.uint32(16).int32(message.marketDataRequestRejectReason);
        }
        if (message.text !== '') {
            writer.uint32(26).string(message.text);
        }
        if (message.assetType !== 1) {
            writer.uint32(32).int32(message.assetType);
        }
        if (message.exchangeCode !== '') {
            writer.uint32(42).string(message.exchangeCode);
        }
        if (message.securityId !== '') {
            writer.uint32(50).string(message.securityId);
        }
        if (message.securityCode !== '') {
            writer.uint32(58).string(message.securityCode);
        }
        if (message.requestedByUser !== '') {
            writer.uint32(66).string(message.requestedByUser);
        }
        if (message.productType !== 1) {
            writer.uint32(72).int32(message.productType);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): MarketDataRequestRejectDownstreamEvent {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMarketDataRequestRejectDownstreamEvent();
        try {
            while (reader.pos < end) {
                const tag = reader.uint32();
                switch (tag >>> 3) {
                    case 1:
                        message.marketDataRequestId = reader.string();
                        break;
                    case 2:
                        message.marketDataRequestRejectReason = reader.int32() as any;
                        break;
                    case 3:
                        message.text = reader.string();
                        break;
                    case 4:
                        message.assetType = reader.int32() as any;
                        break;
                    case 5:
                        message.exchangeCode = reader.string();
                        break;
                    case 6:
                        message.securityId = reader.string();
                        break;
                    case 7:
                        message.securityCode = reader.string();
                        break;
                    case 8:
                        message.requestedByUser = reader.string();
                        break;
                    case 9:
                        message.productType = reader.int32() as any;
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                }
            }
            return message;
        } catch (e) {
            console.error(e, 'Error decoding MarketDataRequestRejectDownstreamEvent, returning empty object...');
            return createBaseMarketDataRequestRejectDownstreamEvent();
        }
    },

    fromPartial(object: DeepPartial<MarketDataRequestRejectDownstreamEvent>): MarketDataRequestRejectDownstreamEvent {
        const message = createBaseMarketDataRequestRejectDownstreamEvent();
        message.marketDataRequestId = object.marketDataRequestId ?? '';
        message.marketDataRequestRejectReason = object.marketDataRequestRejectReason ?? 1;
        message.text = object.text ?? '';
        message.assetType = object.assetType ?? 1;
        message.exchangeCode = object.exchangeCode ?? '';
        message.securityId = object.securityId ?? '';
        message.securityCode = object.securityCode ?? '';
        message.requestedByUser = object.requestedByUser ?? '';
        message.productType = object.productType ?? 1;
        return message;
    }
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
    ? T
    : T extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : T extends {}
    ? { [K in keyof T]?: DeepPartial<T[K]> }
    : Partial<T>;

function longToString(long: Long) {
    return long.toString();
}

if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}
