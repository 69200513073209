/* eslint-disable */
import { CallContext, CallOptions } from 'nice-grpc-common';
import { UserAccountDownstreamEvent, DeleteUserAccountNotification } from './DownstreamUserAccountProto';
import { FindUserAccountsByUsername, CreateUserAccount, DeleteUserAccount } from './UpstreamUserAccountProto';
import _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.celertech.staticdata.api.user.account';

export interface UserAccountsCollection {
    userAccount: UserAccountDownstreamEvent[];
}

function createBaseUserAccountsCollection(): UserAccountsCollection {
    return { userAccount: [] };
}

export const UserAccountsCollection = {
    encode(message: UserAccountsCollection, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        for (const v of message.userAccount) {
            UserAccountDownstreamEvent.encode(v!, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): UserAccountsCollection {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUserAccountsCollection();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.userAccount.push(UserAccountDownstreamEvent.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<UserAccountsCollection>): UserAccountsCollection {
        const message = createBaseUserAccountsCollection();
        message.userAccount = object.userAccount?.map((e) => UserAccountDownstreamEvent.fromPartial(e)) || [];
        return message;
    }
};

export type UserAccountServiceDefinition = typeof UserAccountServiceDefinition;
export const UserAccountServiceDefinition = {
    name: 'UserAccountService',
    fullName: 'com.celertech.staticdata.api.user.account.UserAccountService',
    methods: {
        findUserAccountsByUsername: {
            name: 'findUserAccountsByUsername',
            requestType: FindUserAccountsByUsername,
            requestStream: false,
            responseType: UserAccountsCollection,
            responseStream: false,
            options: {}
        },
        findAllAccountsByUser: {
            name: 'findAllAccountsByUser',
            requestType: FindUserAccountsByUsername,
            requestStream: false,
            responseType: UserAccountsCollection,
            responseStream: false,
            options: {}
        },
        createUserAccount: {
            name: 'createUserAccount',
            requestType: CreateUserAccount,
            requestStream: false,
            responseType: UserAccountDownstreamEvent,
            responseStream: false,
            options: {}
        },
        deleteUserAccount: {
            name: 'deleteUserAccount',
            requestType: DeleteUserAccount,
            requestStream: false,
            responseType: DeleteUserAccountNotification,
            responseStream: false,
            options: {}
        }
    }
} as const;

export interface UserAccountServiceServiceImplementation<CallContextExt = {}> {
    findUserAccountsByUsername(
        request: FindUserAccountsByUsername,
        context: CallContext & CallContextExt
    ): Promise<DeepPartial<UserAccountsCollection>>;
    findAllAccountsByUser(
        request: FindUserAccountsByUsername,
        context: CallContext & CallContextExt
    ): Promise<DeepPartial<UserAccountsCollection>>;
    createUserAccount(
        request: CreateUserAccount,
        context: CallContext & CallContextExt
    ): Promise<DeepPartial<UserAccountDownstreamEvent>>;
    deleteUserAccount(
        request: DeleteUserAccount,
        context: CallContext & CallContextExt
    ): Promise<DeepPartial<DeleteUserAccountNotification>>;
}

export interface UserAccountServiceClient<CallOptionsExt = {}> {
    findUserAccountsByUsername(
        request: DeepPartial<FindUserAccountsByUsername>,
        options?: CallOptions & CallOptionsExt
    ): Promise<UserAccountsCollection>;
    findAllAccountsByUser(
        request: DeepPartial<FindUserAccountsByUsername>,
        options?: CallOptions & CallOptionsExt
    ): Promise<UserAccountsCollection>;
    createUserAccount(
        request: DeepPartial<CreateUserAccount>,
        options?: CallOptions & CallOptionsExt
    ): Promise<UserAccountDownstreamEvent>;
    deleteUserAccount(
        request: DeepPartial<DeleteUserAccount>,
        options?: CallOptions & CallOptionsExt
    ): Promise<DeleteUserAccountNotification>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
    ? T
    : T extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : T extends {}
    ? { [K in keyof T]?: DeepPartial<T[K]> }
    : Partial<T>;
