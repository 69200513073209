import cn from 'classnames';
import { forwardRef, MutableRefObject, Ref } from 'react';
import { useDateFormatter } from 'react-aria';
import { MdCalendarMonth, MdExpandMore } from 'react-icons/md';
import { ExtendedDatePicker } from './SearchControls';

interface DatePickerInputProps {
    label: string;
    value?: string;
    disabled?: boolean;
    onClick?(): void;
    parentRef?: MutableRefObject<ExtendedDatePicker | null>;
}

const DatePickerInput = forwardRef(
    ({ label, value, disabled, onClick, parentRef }: DatePickerInputProps, ref: Ref<HTMLButtonElement>) => {
        const dateFormatter = useDateFormatter();
        const date = value ? new Date(value) : new Date();

        return (
            <div className="relative">
                <div className="absolute -top-[8px] text-xs ml-2 text-neutral-200 px-1 bg-background">{label}</div>
                <button
                    className={cn(
                        'flex items-center justify-between gap-2 text-sm bg-brand-background border border-neutral-700 px-2 py-1 rounded-md h-9',
                        {
                            ['text-neutral-400 cursor-not-allowed']: disabled,
                            ['text-neutral-200 cursor-pointer']: !disabled
                        }
                    )}
                    onClick={() => {
                        if (onClick) onClick();
                        if (parentRef) parentRef.current?.setOpen(!parentRef.current.state.open);
                    }}
                    ref={ref}>
                    <MdCalendarMonth className="w-4 h-4" />
                    <div className="-mb-0.5">{dateFormatter.format(date)}</div>
                    <MdExpandMore className="h-4 w-4 text-gray-400" aria-hidden="true" />
                </button>
            </div>
        );
    }
);

DatePickerInput.displayName = 'DatePickerInput';

export default DatePickerInput;
