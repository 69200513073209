import { portalURL } from '@/helpers/environmentHelper';
import { useAppDispatch, useAppSelector } from '@/state/hooks';
import {
    selectCredentials,
    selectCurrentAccount,
    selectCurrentAccountName,
    setCurrentAccount,
    setCurrentAccountName
} from '@/state/reducers/authSlice';
import {
    openNotifications,
    selectNotificationsOpened,
    setNotificationsVisited
} from '@/state/reducers/notificationSlice';
import { UseDisclosureReturn } from '@/utils/hooks/useDisclosure';
import useXplorPortal from '@/utils/hooks/useXplorPortal';
import { navigateAccordingToAccountType } from '@/utils/navigation';
import { useSso } from '@/utils/providers/SSOProvider';
import { showLayoutSwitch } from '@/utils/route';
import { Menu, Transition } from '@headlessui/react';
import cn from 'classnames';
import { ButtonHTMLAttributes, Fragment, useMemo } from 'react';
import { IconType } from 'react-icons/lib';
import {
    MdApps,
    MdCandlestickChart,
    MdExpandMore,
    MdOfflineBolt,
    MdOutlineAccountBalance,
    MdOutlineAccountBalanceWallet,
    MdOutlineAccountBox,
    MdOutlineAccountCircle,
    MdOutlineAdminPanelSettings,
    MdOutlineLogout,
    MdOutlineSettings,
    MdSwitchAccount
} from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { DropDown } from '../common/Dropdown';
import NotificationButton from '../common/NotificationButton';
import { onLogout, onUserChange } from './Navbar';

interface DesktopNavbarProps {
    depositDisclosure: UseDisclosureReturn;
    withdrawalDisclosure: UseDisclosureReturn;
    settingsDisclosure: UseDisclosureReturn;
}

const DesktopNavbar = ({ depositDisclosure, withdrawalDisclosure, settingsDisclosure }: DesktopNavbarProps) => {
    const dispatch = useAppDispatch();
    const credentials = useAppSelector(selectCredentials);
    const currentAccount = useAppSelector(selectCurrentAccount);
    const currentAccountName = useAppSelector(selectCurrentAccountName);
    const notificationsOpened = useAppSelector(selectNotificationsOpened);

    const navigate = useNavigate();
    const { depositEnabled, withdrawalEnabled, canRequestDeposit, canRequestWithdrawal, isSSOEnabled } = useSso();
    const { onXplorPortal } = useXplorPortal(credentials);

    const currentAccountType = useMemo(() => {
        const properties = credentials?.accounts.find((account) => account.code === currentAccount)?.properties || [];
        return properties.find((property) => property.key === 'TYPE')?.value || 'NOP';
    }, [credentials, currentAccount]);

    return (
        <div className="hidden lg:flex flex-row items-center gap-3 py-2">
            <NotificationButton
                size="w-5 h-5"
                isActive={notificationsOpened}
                onClick={() => {
                    dispatch(setNotificationsVisited(true));
                    dispatch(openNotifications());
                }}
            />
            {showLayoutSwitch && !location.pathname.includes('/trader') && (
                <div className="flex z-20">
                    <Menu as="div" className="relative inline-block text-left border border-neutral-700 rounded-md">
                        <Menu.Button className="flex gap-2 text-sm items-center p-1 justify-between text-neutral-200 bg-brand-background-dark rounded-md">
                            <MdApps className="w-5 h-5" />
                        </Menu.Button>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95">
                            <Menu.Items className="absolute mt-2 w-48 origin-top-right divide-y divide-white/10 rounded-md py-1 bg-brand-background-dark border border-neutral-700 overflow-hidden right-0">
                                <MenuItemButton
                                    label="XplorSpot"
                                    Icon={MdCandlestickChart}
                                    onClick={() => navigate('/')}
                                    disabled={currentAccountType !== 'NOP'}
                                    disabledMessage="Margin accounts are not supported in XplorSpot"
                                />
                                <MenuItemButton
                                    label="XplorSpotLite"
                                    Icon={MdOfflineBolt}
                                    onClick={() => navigate('/lite')}
                                    disabled={currentAccountType !== 'NOP'}
                                    disabledMessage="Margin accounts are not supported in XplorSpotLite"
                                />
                            </Menu.Items>
                        </Transition>
                    </Menu>
                </div>
            )}
            <DropDown
                label="Switch Accounts"
                button={<MenuButton label={currentAccountName} Icon={MdOutlineAccountBox} />}>
                {credentials?.accounts.map((account, i) => {
                    return (
                        <MenuItemButton
                            key={i}
                            label={account.name}
                            Icon={MdSwitchAccount}
                            onClick={() => {
                                dispatch(setCurrentAccount(account.code));
                                dispatch(setCurrentAccountName(account.name));
                                onUserChange(credentials, dispatch);
                                navigateAccordingToAccountType(account, navigate);
                            }}
                        />
                    );
                })}
            </DropDown>
            <DropDown label="User" button={<MenuButton label={credentials?.username} Icon={MdOutlineAccountCircle} />}>
                {portalURL && (
                    <MenuItemButton
                        label="XplorPortal"
                        Icon={MdOutlineAdminPanelSettings}
                        onClick={() => onXplorPortal()}
                    />
                )}
                {depositEnabled && (
                    <MenuItemButton
                        label="Deposits"
                        Icon={MdOutlineAccountBalanceWallet}
                        onClick={depositDisclosure[1].toggle}
                        disabled={!isSSOEnabled || !canRequestDeposit}
                    />
                )}
                {withdrawalEnabled && (
                    <MenuItemButton
                        label="Withdrawals"
                        Icon={MdOutlineAccountBalance}
                        onClick={withdrawalDisclosure[1].toggle}
                        disabled={!isSSOEnabled || !canRequestWithdrawal}
                    />
                )}
                <MenuItemButton label="Settings" Icon={MdOutlineSettings} onClick={settingsDisclosure[1].toggle} />
                <MenuItemButton
                    label="Log out"
                    Icon={MdOutlineLogout}
                    onClick={() => onLogout(credentials, dispatch)}
                />
            </DropDown>
        </div>
    );
};

export default DesktopNavbar;

interface MenuButtonProps {
    Icon: IconType;
    label?: string;
}

const MenuButton = (props: MenuButtonProps) => {
    const { Icon, label } = props;

    return (
        <Menu.Button className="flex w-56 gap-2 text-sm items-center px-2 p-1 justify-between text-neutral-200 bg-brand-background-dark rounded-md">
            <span>
                <Icon className="h-5 w-5" />
            </span>
            <div className="truncate whitespace-nowrap">{label}</div>
            <span className="ml-auto">
                <MdExpandMore className="h-5 w-5" />
            </span>
        </Menu.Button>
    );
};

interface MenuItemButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    Icon: IconType;
    label: string;
    disabledMessage?: string;
}

const MenuItemButton = (props: MenuItemButtonProps) => {
    const { Icon, label, disabledMessage, ...restProps } = props;
    const { disabled } = restProps;
    return (
        <Menu.Item>
            <button
                title={disabled ? disabledMessage : ''}
                className={cn('group flex w-full gap-2 items-center p-2 text-sm bg-brand-background-dark', {
                    'text-neutral-400 cursor-not-allowed': disabled,
                    'text-neutral-200 hover:bg-brand-primary': !disabled
                })}
                {...restProps}>
                <span>
                    <Icon className="h-5 w-5" />
                </span>
                <div className="truncate whitespace-nowrap">{label}</div>
            </button>
        </Menu.Item>
    );
};
