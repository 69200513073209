/* eslint-disable */
import { CallContext, CallOptions } from 'nice-grpc-common';
import { UserLimitDownstreamEvent, DeleteUserLimitNotification } from './DownstreamUserLimitProto';
import {
    FindUserLimitsByUsername,
    CreateUserLimitRequest,
    UpdateUserLimitRequest,
    DeleteUserLimitRequest
} from './UpstreamUserLimitProto';
import _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.celertech.staticdata.api.user.limit';

export interface UserLimitsCollection {
    limit: UserLimitDownstreamEvent[];
}

function createBaseUserLimitsCollection(): UserLimitsCollection {
    return { limit: [] };
}

export const UserLimitsCollection = {
    encode(message: UserLimitsCollection, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        for (const v of message.limit) {
            UserLimitDownstreamEvent.encode(v!, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): UserLimitsCollection {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUserLimitsCollection();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.limit.push(UserLimitDownstreamEvent.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<UserLimitsCollection>): UserLimitsCollection {
        const message = createBaseUserLimitsCollection();
        message.limit = object.limit?.map((e) => UserLimitDownstreamEvent.fromPartial(e)) || [];
        return message;
    }
};

export type UserLimitServiceDefinition = typeof UserLimitServiceDefinition;
export const UserLimitServiceDefinition = {
    name: 'UserLimitService',
    fullName: 'com.celertech.staticdata.api.user.limit.UserLimitService',
    methods: {
        findUserLimitsByUsername: {
            name: 'findUserLimitsByUsername',
            requestType: FindUserLimitsByUsername,
            requestStream: false,
            responseType: UserLimitsCollection,
            responseStream: false,
            options: {}
        },
        createLimit: {
            name: 'createLimit',
            requestType: CreateUserLimitRequest,
            requestStream: false,
            responseType: UserLimitDownstreamEvent,
            responseStream: false,
            options: {}
        },
        updateLimit: {
            name: 'updateLimit',
            requestType: UpdateUserLimitRequest,
            requestStream: false,
            responseType: UserLimitDownstreamEvent,
            responseStream: false,
            options: {}
        },
        deleteLimit: {
            name: 'deleteLimit',
            requestType: DeleteUserLimitRequest,
            requestStream: false,
            responseType: DeleteUserLimitNotification,
            responseStream: false,
            options: {}
        }
    }
} as const;

export interface UserLimitServiceServiceImplementation<CallContextExt = {}> {
    findUserLimitsByUsername(
        request: FindUserLimitsByUsername,
        context: CallContext & CallContextExt
    ): Promise<DeepPartial<UserLimitsCollection>>;
    createLimit(
        request: CreateUserLimitRequest,
        context: CallContext & CallContextExt
    ): Promise<DeepPartial<UserLimitDownstreamEvent>>;
    updateLimit(
        request: UpdateUserLimitRequest,
        context: CallContext & CallContextExt
    ): Promise<DeepPartial<UserLimitDownstreamEvent>>;
    deleteLimit(
        request: DeleteUserLimitRequest,
        context: CallContext & CallContextExt
    ): Promise<DeepPartial<DeleteUserLimitNotification>>;
}

export interface UserLimitServiceClient<CallOptionsExt = {}> {
    findUserLimitsByUsername(
        request: DeepPartial<FindUserLimitsByUsername>,
        options?: CallOptions & CallOptionsExt
    ): Promise<UserLimitsCollection>;
    createLimit(
        request: DeepPartial<CreateUserLimitRequest>,
        options?: CallOptions & CallOptionsExt
    ): Promise<UserLimitDownstreamEvent>;
    updateLimit(
        request: DeepPartial<UpdateUserLimitRequest>,
        options?: CallOptions & CallOptionsExt
    ): Promise<UserLimitDownstreamEvent>;
    deleteLimit(
        request: DeepPartial<DeleteUserLimitRequest>,
        options?: CallOptions & CallOptionsExt
    ): Promise<DeleteUserLimitNotification>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
    ? T
    : T extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : T extends {}
    ? { [K in keyof T]?: DeepPartial<T[K]> }
    : Partial<T>;
