import Divider from '@/components/common/Divider';
import ExportCSV from '@/components/common/ExportCSV';
import Loader from '@/components/common/Loader';
import PopOut, { PopOutHeader, PopOutTitle } from '@/components/common/PopOut';
import Tooltip from '@/components/common/Tooltip';
import HistoricBlotterTable from '@/components/modules/Blotters/HistoricBlotterTable';
import { useAppDispatch, useAppSelector } from '@/state/hooks';
import { selectHistoricTrades, setHistoricTrades } from '@/state/reducers/blotterSlice';
import useScreenSizes from '@/utils/hooks/useScreenSizes';
import { copyStyles } from '@/utils/popout';
import { Fragment, useEffect, useState } from 'react';
import { isTablet } from 'react-device-detect';
import { createPortal } from 'react-dom';
import { MdOpenInNew } from 'react-icons/md';
import Modal, { ModalClose, ModalHeader, ModalProps, ModalTitle } from '../Modal';
import SearchControls from './SearchControls';

const HistoricSearchModal = (props: ModalProps) => {
    const { opened, handlers } = props;
    const dispatch = useAppDispatch();
    const [popOut, setPopOut] = useState<Window | null>(null);

    const screen = useScreenSizes();

    useEffect(() => {
        dispatch(setHistoricTrades(null));
        if (opened && popOut) {
            popOut.close();
            setPopOut(null);
        }
    }, [opened]);

    const openPopOutWindow = () => {
        const popOutWindow = window.open('', '', 'width=1200,height=600');
        if (popOutWindow) {
            copyStyles(document, popOutWindow.document);
            setPopOut(popOutWindow);
            handlers.close();
        }
    };

    return (
        <Fragment>
            <Modal {...props} size="sm:max-w-[90vw] h-full lg:h-[600px]">
                <ModalHeader>
                    <div className="flex space-x-2 items-center">
                        <ModalTitle>Historic Search</ModalTitle>
                        {(screen.laptop || screen.desktop) && !isTablet && (
                            <Tooltip
                                width={120}
                                content="Open in Separate Window"
                                containerClassName="h-5"
                                placement="top-start">
                                <button type="button" className="text-xl" onClick={openPopOutWindow}>
                                    <MdOpenInNew />
                                </button>
                            </Tooltip>
                        )}
                    </div>
                    <ModalClose handlers={handlers} />
                </ModalHeader>
                <HistoricSearchBody />
            </Modal>
            {popOut &&
                createPortal(
                    <PopOut>
                        <PopOutHeader>
                            <PopOutTitle>Historic Search</PopOutTitle>
                        </PopOutHeader>
                        <HistoricSearchBody />
                    </PopOut>,
                    popOut.document.body
                )}
        </Fragment>
    );
};

export default HistoricSearchModal;

const HistoricSearchBody = () => {
    const historicTrades = useAppSelector(selectHistoricTrades);

    const [CSVData, setCSVData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    return (
        <div className="flex flex-col w-full h-full">
            <Divider />
            <SearchControls isLoading={isLoading} setIsLoading={setIsLoading}>
                {isLoading && (
                    <div className="flex justify-center items-center flex-1">
                        <Loader className="text-brand-primary" />
                    </div>
                )}

                {!isLoading && !!historicTrades?.length && (
                    <div className="flex flex-col flex-1 p-2">
                        <div className="flex items-center gap-2 mb-2 text-neutral-200">
                            <div className="text-sm font-bold">Search Results</div>
                            <ExportCSV csvData={CSVData} />
                        </div>
                        <div className="flex-1 basis-0 overflow-hidden">
                            <HistoricBlotterTable setCSVData={setCSVData} historicTrades={historicTrades || []} />
                        </div>
                        <div className="text-sm font-bold text-neutral-200 mt-2">
                            Rows returned: {CSVData.length}, out of a maximum of 200
                        </div>
                    </div>
                )}

                {!isLoading && (
                    <>
                        {!historicTrades && (
                            <div className="historic-search-empty p-2 flex justify-center items-center text-center flex-1 text-neutral-400 text-sm font-bold italic">
                                <div>Fill In The Above Criteria</div>
                            </div>
                        )}
                        {historicTrades?.length === 0 && (
                            <div className="historic-search-empty p-2 flex justify-center items-center text-center flex-1 text-neutral-400 text-sm font-bold italic">
                                <div>No Orders Found. Please Try Another Search Criteria</div>
                            </div>
                        )}
                    </>
                )}
            </SearchControls>
        </div>
    );
};
