import { InstrumentConfig } from '@/config/instruments';
import { TraderMarket } from '@/state/reducers/traderMarketSlice';
import { Combobox, Transition } from '@headlessui/react';
import cn from 'classnames';
import { Fragment, useMemo, useState } from 'react';
import { MdExpandMore, MdSearch } from 'react-icons/md';

interface Option {
    label: string;
    value?: string;
    market?: TraderMarket;
    config?: InstrumentConfig;
}

interface InstrumentSelectorProps {
    options: Option[];
    selected?: Option;
    parentSize?: DOMRectReadOnly;
    onSubmit: (e: TraderMarket) => void;
}

const categoryOptions = [
    { label: 'Show All', value: 'All' },
    { label: 'Crypto CFD', value: 'Crypto' },
    { label: 'FX Spot', value: 'FX' },
    { label: 'Index CFD', value: 'Index' },
    { label: 'Commodity CFD', value: 'Commodity' }
];

const InstrumentSelector = (props: InstrumentSelectorProps) => {
    const { options, selected, parentSize, onSubmit } = props;
    const [query, setQuery] = useState<any>('');
    const [categoryFilter, setCategoryFilter] = useState<any>('All');

    const filteredOptions = useMemo(() => {
        if (query) {
            const temp = query.replace('/', '').toUpperCase();
            return options
                .filter((option) => option.label.replace('/', '').match(temp))
                .filter((option) => option.config?.type === categoryFilter || categoryFilter === 'All');
        }
        return options.filter((option) => option.config?.type === categoryFilter || categoryFilter === 'All');
    }, [options, query, categoryFilter]);

    const { shouldFullWidth, shouldAutoWidth } = useMemo(() => {
        if (!parentSize) return { shouldFullWidth: false, shouldAutoWidth: false };
        else if (parentSize.width >= 300 && parentSize.width < 580)
            return { shouldFullWidth: true, shouldAutoWidth: false };
        else if (parentSize.width < 300) return { shouldFullWidth: true, shouldAutoWidth: true };
        return { shouldFullWidth: false, shouldAutoWidth: false };
    }, [parentSize]);

    return (
        <Combobox value={[]} multiple>
            <div
                className={cn('relative', {
                    'w-full': shouldFullWidth
                })}>
                <div className="relative w-full cursor-default overflow-hidden bg-brand-background-dark border border-neutral-700 hover:border-neutral-600 text-left shadow-md sm:text-xs">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-1">
                        <MdSearch className="h-5 w-5 text-neutral-400" aria-hidden="true" />
                    </div>
                    <Combobox.Input
                        className="w-full border-none py-1 pl-7 pr-10 text-xs leading-5 text-neutral-200 bg-brand-background-dark outline-none placeholder:text-neutral-500"
                        autoComplete="off"
                        autoCorrect="off"
                        placeholder={selected?.label}
                        onChange={(event) => setQuery(event.target.value)}
                    />
                    <Combobox.Button>
                        <div className="absolute inset-y-0 right-0 flex items-center pr-1">
                            <MdExpandMore className="h-5 w-5 text-neutral-400" aria-hidden="true" />
                        </div>
                    </Combobox.Button>
                </div>
                <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    afterLeave={() => setQuery('')}>
                    <Combobox.Options className="absolute right-0 mt-2 z-[60] flex cursor-default bg-brand-background-dark border border-neutral-700 text-sm shadow-lg shadow-neutral-900 overflow-clip">
                        <div className="flex flex-col text-xs w-min border-r border-neutral-600">
                            {categoryOptions.map((option, optionIdx) => {
                                return (
                                    <div
                                        key={optionIdx}
                                        className={cn('cursor-pointer hover:bg-brand-primary p-2', {
                                            'bg-brand-primary': categoryFilter === option.value
                                        })}
                                        onClick={() => setCategoryFilter(option.value)}>
                                        {option.label}
                                    </div>
                                );
                            })}
                        </div>
                        <div
                            className={cn('flex flex-col max-h-60 overflow-auto', {
                                'w-[203px]': !shouldAutoWidth,
                                'w-[140px]': shouldAutoWidth
                            })}>
                            {filteredOptions.length === 0 && query !== '' ? (
                                <div className="relative select-none py-2 px-4 text-neutral-200">Nothing found.</div>
                            ) : (
                                filteredOptions.map((option, optionIdx) => {
                                    const type = option.config?.type;
                                    return (
                                        <Combobox.Option
                                            key={optionIdx}
                                            className="relative cursor-pointer select-none py-2 pl-3 pr-4 group hover:bg-brand-primary text-neutral-200"
                                            onClick={() => {
                                                if (option.market) onSubmit(option.market);
                                            }}
                                            value={option}>
                                            <span className="block truncate text-sm">{option?.label}</span>
                                            <span className="text-xs text-neutral-400 group-hover:text-neutral-200">
                                                {type ? `${type} ${type === 'FX' ? 'Spot' : 'CFD'}` : '-'}
                                            </span>
                                        </Combobox.Option>
                                    );
                                })
                            )}
                        </div>
                    </Combobox.Options>
                </Transition>
            </div>
        </Combobox>
    );
};

export default InstrumentSelector;
