import { useAppSelector } from '@/state/hooks';
import { selectFavouriteMarketPairs, selectMarketPairs } from '@/state/reducers/marketPairSlice';
import { CSSProperties, useMemo, useState } from 'react';
import { MdSearch } from 'react-icons/md';
import Input from '../../inputs/Input';
import UserMarket from './UserMarket';

import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';

interface MarketListProps {
    onClick?: () => void;
}

export default function MarketList(props: MarketListProps) {
    const { onClick } = props;

    const userMarkets = useAppSelector(selectMarketPairs);
    const userFavouriteMarkets = useAppSelector(selectFavouriteMarketPairs);

    const [filter, setFilter] = useState('');

    const userFavouriteMarketsCeler = useMemo(() => {
        return userFavouriteMarkets.map((market) => market.celer);
    }, [userFavouriteMarkets]);

    const sortedFavouriteMarkets = useMemo(() => {
        const temp = [...userFavouriteMarkets];
        temp.sort((a, b) => {
            if (a.celer < b.celer) return -1;
            if (a.celer > b.celer) return 1;
            return 0;
        });
        return temp;
    }, [userFavouriteMarkets]);

    const sortedUserMarkets = useMemo(() => {
        const temp = userMarkets.filter((market) => !userFavouriteMarketsCeler.includes(market.celer));
        return [...sortedFavouriteMarkets, ...temp];
    }, [userMarkets, userFavouriteMarketsCeler]);

    const filteredUserMarkets = useMemo(() => {
        const temp = filter.replace('/', '').toUpperCase();
        return sortedUserMarkets.filter((market) => market.show.replace('/', '').match(temp));
    }, [sortedFavouriteMarkets, sortedUserMarkets, filter]);

    const VirtualizedUserMarket = ({ index, style }: ListItemProps) => {
        const market = filteredUserMarkets[index];

        return (
            <UserMarket
                style={style}
                onClick={onClick}
                market={market}
                userFavouriteMarketsCeler={userFavouriteMarketsCeler}
            />
        );
    };

    return (
        <div className="h-full w-full flex flex-col">
            <div className="p-2">
                <Input placeholder="Search" icon={<MdSearch />} onChange={(e) => setFilter(e.target.value)} />
            </div>

            <div className="w-full flex text-gray-300 text-sm pl-2 pr-3.5">
                <div className="min-w-[90px] w-1/3">Pair</div>
                <div className="min-w-[80px] flex-1 flex justify-start">Bid</div>
                <div className="min-w-[20px] flex items-center justify-center px-1">
                    {/* <div className="hidden 2xl:block">Spread</div> */}
                </div>
                <div className="min-w-[80px] flex-1 flex justify-end">Ask</div>
            </div>

            <div className="relative self-stretch grow basis-full">
                <AutoSizer>
                    {({ height, width }) => (
                        <FixedSizeList
                            height={height}
                            width={width}
                            itemCount={filteredUserMarkets.length}
                            itemSize={26}>
                            {VirtualizedUserMarket}
                        </FixedSizeList>
                    )}
                </AutoSizer>
            </div>
        </div>
    );
}

export interface ListItemProps {
    index: number;
    style: CSSProperties;
}
