import MarketList from '@/components/modules/MarketList';
import { Highlights, UserFavourite } from '@/components/modules/MarketList/UserMarket';
import { useAppSelector } from '@/state/hooks';
import { PairMap, selectFavouriteMarketPairs } from '@/state/reducers/marketPairSlice';
import useActivePair from '@/utils/hooks/useActivePair';
import { useClickOutside } from '@/utils/hooks/useClickOutside';
import { useDidUpdate } from '@/utils/hooks/useDidUpdate';
import { useDisclosure } from '@/utils/hooks/useDisclosure';
import { GetColorIndexesReturn, useInstrument } from '@/utils/hooks/useInstrument';
import usePreviousSelector from '@/utils/hooks/usePreviousSelector';
import { useMemo, useState } from 'react';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';

const MobileSubHeader = () => {
    const userFavouriteMarkets = useAppSelector(selectFavouriteMarketPairs);

    const activePair = useActivePair();
    const marketListDisclosure = useDisclosure(false);
    const ref = useClickOutside(marketListDisclosure[1].close);

    const userFavouriteMarketsCeler = useMemo(
        () => userFavouriteMarkets.map((market) => market.celer),
        [userFavouriteMarkets]
    );

    return (
        <div
            ref={ref}
            className="flex lg:hidden relative w-full bg-brand-background border-[1.5px] border-brand-primary border-b-0 h-10 text-neutral-200">
            <div
                className="flex items-center justify-between w-full text-sm p-2 px-1.5 cursor-pointer hover:bg-brand-background-dark"
                onClick={marketListDisclosure[1].toggle}>
                <div className="flex items-center gap-2">
                    <UserFavourite market={activePair} userFavouriteMarketsCeler={userFavouriteMarketsCeler} />
                    <CelerPrice market={activePair} />
                </div>
                {marketListDisclosure[0] ? <MdExpandLess className="h-5 w-5" /> : <MdExpandMore className="h-5 w-5" />}
            </div>

            {marketListDisclosure[0] && (
                <div className="pop-up-market-list absolute top-10 z-10 w-full bg-brand-background">
                    <MarketList onClick={marketListDisclosure[1].close} />
                </div>
            )}
        </div>
    );
};

const CelerPrice = ({ market }: { market: PairMap }) => {
    const activePair = useActivePair();

    const priceBooksMidPrice = useAppSelector((state) => state.celerMarket.priceBooks[activePair.celer]?.midprice);
    const prevPriceBooksMidPrice = usePreviousSelector(
        (state) => state.celerMarket.priceBooks[activePair.celer]?.midprice
    ) as number;

    const [priceHighlights, setPriceHighlights] = useState<GetColorIndexesReturn | undefined>();

    const { formatPip, formatHighlights } = useInstrument(market.celer);

    // currently displaying close
    const midPrice = useMemo(() => priceBooksMidPrice && formatPip(priceBooksMidPrice), [priceBooksMidPrice]);

    // // for highlighting changes based on prev price
    useDidUpdate(() => {
        if (priceBooksMidPrice && prevPriceBooksMidPrice)
            setPriceHighlights(formatHighlights(prevPriceBooksMidPrice, priceBooksMidPrice, priceHighlights));
    }, [priceBooksMidPrice]);

    return (
        <div className="flex items-center gap-2">
            {priceBooksMidPrice > 0 && midPrice && (
                <span>
                    <Highlights ticker={midPrice} highlights={priceHighlights} />
                </span>
            )}
        </div>
    );
};

export default MobileSubHeader;

// const candle = useAppSelector(selectCurrentCandle);
// const priceTrend = useAppSelector(selectPriceTrend);
// const prevCandle = usePreviousSelector(selectCurrentCandle) as Candle;
// const earliestCandle = useAppSelector(selectEarlistCandle);

// const priceTrend = useMemo(() => {
//     if (earliestCandle && priceBooksMidPrice) {
//         return BigNumber(priceBooksMidPrice)
//             .minus(earliestCandle?.close)
//             .dividedBy(earliestCandle?.close)
//             .times(100)
//             .toNumber();
//     }
// }, [priceBooksMidPrice, earliestCandle]);

{
    /* {priceTrend && (
                <span
                    className={cn('', {
                        ['text-brand-primary']: priceTrend > 0,
                        ['text-brand-red']: priceTrend < 0,
                        ['text-neutral-200']: priceTrend === 0
                    })}>
                    {priceTrend.toFixed(2)}%
                </span>
            )} */
}
