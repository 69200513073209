import { ExecutionType } from '@/compiled_proto/com/celertech/orderrouting/api/enums/ExecutionTypeProto';
import { Button } from '@/components/common/Button';
import Divider from '@/components/common/Divider';
import Loader from '@/components/common/Loader';
import { searchBlotter } from '@/services/OrderService';
import { useAppDispatch, useAppSelector } from '@/state/hooks';
import { selectCredentials } from '@/state/reducers/authSlice';
import { setHistoricTrades } from '@/state/reducers/blotterSlice';
import { isAfter } from 'date-fns';
import {
    enAU as australia,
    enCA as canada,
    da as danish,
    frCA as fr_canada,
    el as greek,
    enNZ as new_zealand,
    pl as polish,
    tr as turkish,
    uk as ukraine,
    enGB as united_kingdom
} from 'date-fns/locale';
import { Dispatch, ReactNode, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocale } from 'react-aria';
import { default as DatePicker, default as ReactDatePicker, registerLocale } from 'react-datepicker';
import DatePickerInput from './DatePickerInput';

registerLocale('el', greek);
registerLocale('pl', polish);
registerLocale('tr', turkish);
registerLocale('en-AU', australia);
registerLocale('en-GB', united_kingdom);
registerLocale('en-NZ', new_zealand);
registerLocale('en-CA', canada);
registerLocale('fr-CA', fr_canada);
registerLocale('uk', ukraine);
registerLocale('da', danish);

export interface ExtendedDatePicker extends ReactDatePicker<never, undefined> {
    handleBlur(): void;
    handleCalendarClickOutside(): void;
    state: {
        open: boolean;
    };
}

interface SearchControlsProps {
    isLoading: boolean;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    children?: ReactNode;
}

const SearchControls = (props: SearchControlsProps) => {
    const { isLoading, setIsLoading, children } = props;

    const dispatch = useAppDispatch();
    const credentials = useAppSelector(selectCredentials);

    const startRef = useRef<ExtendedDatePicker | null>(null);
    const endRef = useRef<ExtendedDatePicker | null>(null);

    const minTime = useMemo(() => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return today;
    }, []);

    const maxTime = useMemo(() => {
        const today = new Date();
        today.setHours(23, 59, 59, 999);
        return today;
    }, []);

    const [startDate, setStartDate] = useState<Date | null>(minTime);
    const [endDate, setEndDate] = useState<Date | null>(new Date());
    const [error, setError] = useState<{ msg: string }>({ msg: '' });

    const { locale } = useLocale();

    const defaultDatePickerProps = useMemo(
        () => ({
            locale,
            showTimeSelect: true,
            timeIntervals: 30,
            minTime,
            maxTime,
            maxDate: new Date()
        }),
        [minTime, maxTime, locale]
    );

    const searchHistory = useCallback(async () => {
        try {
            if (credentials) {
                setIsLoading(true);
                if (!startDate || !endDate) throw Error('Unknown Date');
                if (isAfter(startDate, endDate)) throw Error('To Date Is Before The From Date');
                setError({ msg: '' });
                const resp = await searchBlotter(credentials, ExecutionType.TRADE, startDate, endDate);
                dispatch(setHistoricTrades(resp));
                setIsLoading(false);
            }
        } catch (e) {
            const msg = (e as Error).message;
            setError({ msg });
            setIsLoading(false);
        }
    }, [startDate, endDate, credentials]);

    useEffect(() => {
        if (startRef.current) {
            startRef.current.handleBlur = () => {};
            startRef.current.handleCalendarClickOutside = () => {};
        }
        if (endRef.current) {
            endRef.current.handleBlur = () => {};
            endRef.current.handleCalendarClickOutside = () => {};
        }
    }, [startRef, endRef]);

    return (
        <>
            <div className="flex flex-col p-2 py-3 sm:py-4 gap-2">
                <div className="relative flex flex-wrap justify-between sm:items-center gap-3">
                    <div className="flex flex-wrap gap-3 items-center">
                        {/* Wrapping Datepicker with span to control gap between controls */}
                        <span>
                            <DatePicker
                                ref={startRef}
                                selected={startDate}
                                onCalendarOpen={() => endRef.current?.setOpen(false)}
                                onChange={(date) => {
                                    setStartDate(date);
                                    startRef.current?.setOpen(false);
                                }}
                                customInput={<DatePickerInput label="From" parentRef={startRef} />}
                                {...defaultDatePickerProps}
                            />
                        </span>
                        <span>
                            <DatePicker
                                ref={endRef}
                                selected={endDate}
                                onCalendarOpen={() => startRef.current?.setOpen(false)}
                                onChange={(date) => {
                                    setEndDate(date);
                                    endRef.current?.setOpen(false);
                                }}
                                customInput={<DatePickerInput label="To" parentRef={endRef} />}
                                {...defaultDatePickerProps}
                            />
                        </span>
                    </div>
                    <div className="hidden md:flex gap-3 text-neutral-200">
                        <button
                            className="px-4 py-1 rounded-md text-sm bg-neutral-600 hover:bg-neutral-500"
                            onClick={() => {
                                dispatch(setHistoricTrades(null));
                                setError({ msg: '' });
                            }}>
                            Clear
                        </button>
                        <Button
                            className="rounded-md text-sm"
                            onClick={searchHistory}
                            isLoading={isLoading}
                            loader={<Loader className="w-4 h-4" />}>
                            Search
                        </Button>
                    </div>
                </div>
                {error.msg && <div className="text-brand-red italic text-sm px-2">{error.msg}</div>}
            </div>
            <Divider />
            {children}
            <span className="flex md:hidden">
                <Divider />
            </span>
            <div className="flex md:hidden p-2 py-3 sm:p-4 gap-3 text-neutral-200">
                <button
                    className="px-4 py-1 rounded-md text-sm bg-neutral-600 hover:bg-neutral-500"
                    onClick={() => dispatch(setHistoricTrades(null))}>
                    Clear
                </button>
                <Button
                    className="rounded-md text-sm w-fit"
                    onClick={searchHistory}
                    isLoading={isLoading}
                    loader={<Loader className="w-4 h-4" />}>
                    Search
                </Button>
            </div>
        </>
    );
};

export default SearchControls;
