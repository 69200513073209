import { defaultColumn, qtyFormatterConfig } from '@/config/config';
import { tradeColumns } from '@/helpers/table/tradeBlotterTableHelper';
import { BlotterTrade } from '@/model/blotters';
import { useAppSelector } from '@/state/hooks';
import { selectCredentials, selectCurrentAccount } from '@/state/reducers/authSlice';
import { selectGeneralSettings } from '@/state/reducers/settingSlice';
import {
    SortingState,
    getCoreRowModel,
    getFacetedMinMaxValues,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable
} from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { useDateFormatter, useLocale, useNumberFormatter } from 'react-aria';
import { mapTradeBlotters } from '../modules/Blotters/TradeBlotterTable';
import Table from '../table/Table';

interface LiteHistoryTableProps {
    historicTrades: BlotterTrade[];
    setCSVData: (data: any[]) => void;
}

const LiteHistoryTable = (props: LiteHistoryTableProps) => {
    const { historicTrades, setCSVData } = props;

    const { locale } = useLocale();
    const credentials = useAppSelector(selectCredentials);
    const currentAccount = useAppSelector(selectCurrentAccount);
    const generalSettings = useAppSelector(selectGeneralSettings);

    const formatDate = useDateFormatter();
    const formatQty = useNumberFormatter(qtyFormatterConfig);

    const [sorting, setSorting] = useState<SortingState>([
        { id: 'executionTime', desc: true },
        { id: 'fillNo', desc: true }
    ]);

    const columns = useMemo(() => tradeColumns(formatQty, generalSettings), [formatQty, generalSettings]);
    const isUTC = useMemo(() => generalSettings.timezone.value === 'UTC', [generalSettings]);
    const formattedTrades: any = useMemo(() => {
        return historicTrades
            .filter((trade) => trade.account === currentAccount || trade.account === currentAccount?.slice(0, 36))
            .map((trade) => mapTradeBlotters(trade, locale, credentials, formatDate, isUTC));
    }, [historicTrades, locale, credentials, currentAccount, isUTC]);

    const tableInstance = useReactTable({
        data: formattedTrades,
        columns,
        state: { sorting },
        defaultColumn,
        enableColumnResizing: true,
        columnResizeMode: 'onChange',
        globalFilterFn: 'includesString',
        onSortingChange: (sorting) => setSorting(sorting),
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        getSubRows: (row: any) => row.subRows,
        enableRowSelection: true
    });

    useEffect(() => {
        setCSVData(formattedTrades);
    }, [formattedTrades]);

    return <Table tableInstance={tableInstance} isLoadingTableContent={false} />;
};

export default LiteHistoryTable;
