import currencyConfig from '@/config/currency';
import { getAssetName } from '@/utils/symbolMapping';
import { ToastOptions } from 'react-hot-toast';
import { Side } from '../compiled_proto/com/celertech/marketdata/api/enums/SideProto';
import { OrderStatus } from '../compiled_proto/com/celertech/orderrouting/api/enums/OrderStatusProto';
import { OrderType } from '../compiled_proto/com/celertech/orderrouting/api/enums/OrderTypeProto';
import { TimeInForceType } from '../compiled_proto/com/celertech/orderrouting/api/enums/TimeInForceTypeProto';
import { FxOrderSnapshotDownstreamEvent } from '../compiled_proto/com/celertech/orderrouting/api/order/DownstreamOrderProto';
import { capitalize, capitalizeAll, getQtyFormatter } from '../utils/format';
import { toastError, toastSuccess } from '../utils/hooks/useToast';
// import { getOtherCurrency } from './currencyHelper';

export const toastOrderStatus = (event: FxOrderSnapshotDownstreamEvent, toastConfig: ToastOptions) => {
    const {
        // securityId,
        qty,
        price,
        stopPx,
        orderId,
        id,
        info,
        currency,
        leg,
        securityCode,
        orderType,
        orderStatus,
        side: orderSide,
        timeInForce,
        lastQty,
        cumQty,
        leavesQty,
        orderMetadata
    } = event;

    const type = OrderType[orderType] as keyof typeof OrderType;
    const side = Side[orderSide] as keyof typeof Side;
    const status = OrderStatus[orderStatus] as keyof typeof OrderStatus;

    const slippage = orderMetadata.find((meta) => meta.key === 'SLIPPAGE')?.value || 0;

    const formatQty = getQtyFormatter();

    // const fmtPrice = formatQty.format(price);
    const fmtLegPrice = formatQty.format(leg[0]?.price);
    const fmtQuantity = formatQty.format(qty);
    const fmtFilledAmount = formatQty.format(lastQty);
    const fmtRemainingAmount = formatQty.format(leavesQty);
    const fmtAccumulatedAmount = formatQty.format(cumQty);

    const ccy = getAssetName(currency);
    const [ccy1, ccy2] = securityCode.split('/');

    const ccy1Config = currencyConfig.find((c) => c.celer === ccy1);
    const ccy2Config = currencyConfig.find((c) => c.celer === ccy2);

    const ccy2Order = currency === ccy2;

    const text = {
        orderType: `${capitalizeAll(type)} Order`,
        side: capitalize(side),
        tif: TimeInForceType[timeInForce].toString(),
        titlePrice: titlePrice(orderType, price, stopPx, slippage)
    };

    let position = '';
    if (ccy2Order) position = `${ccy2Config?.show} vs ${ccy1Config?.show}`;
    else position = `${ccy1Config?.show} vs ${ccy2Config?.show}`;

    const title = `${text.orderType} to ${text.side} ${fmtQuantity} ${position} ${text.titlePrice}`;
    const isExecutedByStopOrder = [OrderType.LIMIT, OrderType.MARKET].includes(orderType) && stopPx > 0;

    switch (status) {
        case 'NEW':
            {
                toastSuccess({
                    body: (
                        <div>
                            {orderType === OrderType.STOP_LIMIT && <p>Max Slippage: {slippage}</p>}
                            <p>Duration: {text.tif}</p>
                            <p>OrderId: {orderId}</p>
                            {isExecutedByStopOrder && <p className="text-neutral-600 mt-2">Executed by a Stop Order</p>}
                        </div>
                    ),
                    title: `${title} Placed`,
                    toastConfig
                });
            }
            break;
        case 'FILLED':
            {
                toastSuccess({
                    body: (
                        <div>
                            <p>Fill price: {fmtLegPrice}</p>
                            <p>
                                Filled Amount: {fmtFilledAmount} {ccy}
                            </p>
                            <p>
                                Remaining Amount: {fmtRemainingAmount} {ccy}
                            </p>
                            <p>
                                Accumulated Amount: {fmtAccumulatedAmount} {ccy}
                            </p>
                            <p>OrderId: {orderId}</p>
                            <p>Exec Id: {id}</p>
                            {isExecutedByStopOrder && <p className="text-neutral-600 mt-2">Executed by a Stop Order</p>}
                        </div>
                    ),
                    title: `${title} Fully Filled`,
                    toastConfig
                });
            }
            break;
        case 'PART_FILLED':
            {
                toastSuccess({
                    body: (
                        <div>
                            <p>Fill price: {fmtLegPrice}</p>
                            <p>
                                Filled Amount: {fmtFilledAmount} {ccy}
                            </p>
                            <p>
                                Remaining Amount: {fmtRemainingAmount} {ccy}
                            </p>
                            <p>
                                Accumulated Amount: {fmtAccumulatedAmount} {ccy}
                            </p>
                            <p>OrderId: {orderId}</p>
                            <p>Exec Id: {id}</p>
                            {isExecutedByStopOrder && <p className="text-neutral-600 mt-2">Executed by a Stop Order</p>}
                        </div>
                    ),
                    title: `${title} Partially Filled`,
                    toastConfig
                });
            }
            break;
        case 'REJECTED':
            {
                toastError({
                    body: (
                        <div>
                            <p>Order Id: {orderId}</p>
                            <p>Exec Id: {id}</p>
                            <p className="text-neutral-600 mt-2">{info}</p>
                        </div>
                    ),
                    title: `${title} Rejected`,
                    persist: true,
                    toastConfig
                });
            }
            break;
        case 'CANCELED':
            {
                toastError({
                    body: (
                        <div>
                            <p>
                                Cancelled Amount: {formatQty.format(qty - cumQty)} {ccy}
                            </p>
                            <p>
                                Remaining Amount: {fmtRemainingAmount} {ccy}
                            </p>
                            <p>
                                Accumulated Amount: {fmtAccumulatedAmount} {ccy}
                            </p>
                            <p>OrderId: {orderId}</p>
                            <p>Exec Id: {id}</p>
                            {isExecutedByStopOrder && <p className="text-neutral-600 mt-2">Executed by a Stop Order</p>}
                        </div>
                    ),
                    title: `${title} Cancelled`,
                    persist: true,
                    toastConfig
                });
            }
            break;
        // case 'PENDING_AMEND':
        //     {
        //         let body = `Amended Price: ${fmtPrice} ${ccy}\n`;
        //         body += `Amended Amount: ${fmtQuantity} ${ccy}\n`;
        //         body += `OrderId: ${orderId}\n`;
        //         body += `Exec Id: ${id}`;

        //         toastSuccess({ body, title: `Pending Amendment for ${title}`, toastConfig });
        //     }
        //     break;
        default:
            break;
    }
};

export const titlePrice = (orderType: OrderType, price: any, stopPx: any, slippage: any) => {
    switch (orderType) {
        case OrderType.LIMIT:
            return ` @ ${price}`;
        case OrderType.STOP_LIMIT:
            return ` @ ${stopPx}`;
        case OrderType.STOP_MARKET:
            return ` @ ${stopPx}`;
        default:
            return '';
    }
};

// TESTING DATA

// export const marketOrder = {
//     id: '1370689010652942337',
//     orderId: '1370689009566617600',
//     externalClOrderId: '91e0bfde-c7d3-40d8-8784-cdce07e3044f',
//     originalExternalClOrderId: '',
//     externalOrderId: '1370689009600176128',
//     secondaryExecId: '1370689010208350208',
//     exchangeCode: 'XCEL',
//     securityCode: 'BTC/USD',
//     securityId: 'BTC/USD',
//     securityIdSource: 'EXCHANGE',
//     externalSecurityCode: 'BTC/USD',
//     externalSecurityId: 'BTC/USD',
//     orderType: 1,
//     strategyType: 1,
//     accountType: 1,
//     account: 'ext-dnh@gc.exchange',
//     side: 1,
//     qty: 0.0001,
//     price: 0,
//     currency: 'BTC',
//     trader: 'ext-dnh@gc.exchange',
//     broker: '',
//     giveUp: '',
//     giveIn: '',
//     lastPx: 21705.5,
//     lastQty: 0.00001,
//     cumQty: 0.0001,
//     leavesQty: 0,
//     avgPx: 21705.5,
//     orderStatus: 3,
//     executionType: 2,
//     orderText: '',
//     info: '',
//     handlingInstruction: 1,
//     parentOrderId: '0',
//     orderVersion: 6,
//     isParentOrder: false,
//     celerClOrderId: '1370689009566617600_0',
//     originalCelerClOrderId: '0',
//     isChildOrder: false,
//     createdTimestampUtcInMillis: '1678344740128',
//     updatedTimestampUtcInMillis: '1678344740387',
//     onBehalfOfCompId: '',
//     deliverToCompId: '',
//     externalExchangeCode: 'XCEL',
//     timeInForce: 6,
//     timeInForceExpiry: '',
//     minQty: 0,
//     maxFloor: 0,
//     quoteId: '',
//     settlementDate: '2023-03-13',
//     checkpointTimestamp: [],
//     refId: '0',
//     bookAsFilled: false,
//     productType: 1,
//     destinationKey: 'CELER_PRICE_ENGINE',
//     algoType: '',
//     commission: 0,
//     leg: [
//         {
//             underlyingExchangeCode: 'XCEL',
//             underlyingSecurityCode: 'BTC/USD',
//             underlyingSecurityId: 'BTC/USD',
//             settlementDate: '2023-03-13',
//             qty: 0.0001,
//             side: 1,
//             price: 21705.5,
//             legForwardPts: 0,
//             spotPrice: 21705.5,
//             settlementType: 'SP',
//             maturityDate: ''
//         }
//     ],
//     tradeAllocGroup: [],
//     tradePartiesGroup: [],
//     settlementType: '',
//     splitValueDate: false,
//     splitLegCurrency1: '',
//     splitLegCurrency2: '',
//     splitLegCurrency1SettlementDate: '',
//     splitLegCurrency2SettlementDate: '',
//     orderMetadata: [
//         {
//             key: 'EXECUTION_METHOD',
//             value: 'ESP'
//         },
//         {
//             key: 'TIERCODE',
//             value: 'NO_TIERING'
//         },
//         {
//             key: 'SLIPPAGE',
//             value: '0.5e-0'
//         },
//         {
//             key: 'STREAMCODE',
//             value: 'INTERNAL'
//         },
//         {
//             key: 'MID_RATE',
//             value: '21703.0'
//         },
//         {
//             key: 'destinationKey',
//             value: 'GCE_PROD_DEMO'
//         }
//     ],
//     orderSource: 'OMS_WEBTRADER',
//     settlCurrency: '',
//     rateSource: '',
//     accountName: 'ext-dnh@gc.exchange',
//     stopPx: 0,
//     sourceExecutionId: '2b59fa8b-db8e-4840-a169-8468e6a2b5c3',
//     destinationBackOfficeKey: '',
//     executionInstruction: 1,
//     maxShow: 0,
//     pegPriceType: 1,
//     lastActionedBy: ''
// };

// export const limitOrder = {
//     ...marketOrder,
//     orderType: 2,
//     price: 1
// };
