export interface CurrencyConfigProps {
    celer: string;
    netdania: string;
    show: string;
    min_order_size: number;
    order_decimals: number;
    increments: number;
    type: 'Crypto' | 'Fiat' | 'Index' | 'Commodity';
    allowDepositWithdrawal: 0 | 1;
    longName: string;
}

export type CurrencyConfigDict = Record<string, CurrencyConfigProps>;

const currencyConfig: CurrencyConfigProps[] = [
    {
        celer: 'ADA',
        netdania: 'ADA',
        show: 'ADA',
        min_order_size: 500,
        order_decimals: 3,
        increments: 25000,
        type: 'Crypto',
        allowDepositWithdrawal: 0,
        longName: 'Cardano'
    },
    {
        celer: 'ATM',
        netdania: 'ATM',
        show: 'ATOM',
        min_order_size: 10,
        order_decimals: 4,
        increments: 1000,
        type: 'Crypto',
        allowDepositWithdrawal: 0,
        longName: 'Cosmos'
    },
    {
        celer: 'AVE',
        netdania: 'AAVE',
        show: 'AAVE',
        min_order_size: 5,
        order_decimals: 5,
        increments: 200,
        type: 'Crypto',
        allowDepositWithdrawal: 1,
        longName: 'Aave'
    },
    {
        celer: 'AVX',
        netdania: 'AVAX',
        show: 'AVAX',
        min_order_size: 10,
        order_decimals: 4,
        increments: 1000,
        type: 'Crypto',
        allowDepositWithdrawal: 0,
        longName: 'Avalanche'
    },
    {
        celer: 'BAT',
        netdania: 'BAT',
        show: 'BAT',
        min_order_size: 500,
        order_decimals: 3,
        increments: 50000,
        type: 'Crypto',
        allowDepositWithdrawal: 1,
        longName: 'Basic Attention Token'
    },
    {
        celer: 'BCH',
        netdania: 'BCH',
        show: 'BCH',
        min_order_size: 1,
        order_decimals: 3,
        increments: 100,
        type: 'Crypto',
        allowDepositWithdrawal: 1,
        longName: 'Bitcoin Cash'
    },
    {
        celer: 'BNB',
        netdania: 'BNB',
        show: 'BNB',
        min_order_size: 1,
        order_decimals: 4,
        increments: 50,
        type: 'Crypto',
        allowDepositWithdrawal: 0,
        longName: 'Binance coin'
    },
    {
        celer: 'BTC',
        netdania: 'BTC',
        show: 'BTC',
        min_order_size: 0.0001,
        order_decimals: 4,
        increments: 0.5,
        type: 'Crypto',
        allowDepositWithdrawal: 1,
        longName: 'Bitcoin'
    },
    {
        celer: 'BUS',
        netdania: 'BUSD',
        show: 'BUSD',
        min_order_size: 100,
        order_decimals: 2,
        increments: 10000,
        type: 'Crypto',
        allowDepositWithdrawal: 1,
        longName: 'Binance USD'
    },
    {
        celer: 'CMP',
        netdania: 'COMP',
        show: 'COMP',
        min_order_size: 5,
        order_decimals: 5,
        increments: 200,
        type: 'Crypto',
        allowDepositWithdrawal: 1,
        longName: 'Compound'
    },
    {
        celer: 'CRV',
        netdania: 'CRV',
        show: 'CRV',
        min_order_size: 100,
        order_decimals: 3,
        increments: 10000,
        type: 'Crypto',
        allowDepositWithdrawal: 1,
        longName: 'Curve'
    },
    {
        celer: 'DAI',
        netdania: 'DAI',
        show: 'DAI',
        min_order_size: 100,
        order_decimals: 3,
        increments: 10000,
        type: 'Crypto',
        allowDepositWithdrawal: 1,
        longName: 'Dai'
    },
    {
        celer: 'DOG',
        netdania: 'DOGE',
        show: 'DOGE',
        min_order_size: 1000,
        order_decimals: 2,
        increments: 100000,
        type: 'Crypto',
        allowDepositWithdrawal: 1,
        longName: 'Dogecoin'
    },
    {
        celer: 'DOT',
        netdania: 'DOT',
        show: 'DOT',
        min_order_size: 20,
        order_decimals: 4,
        increments: 2000,
        type: 'Crypto',
        allowDepositWithdrawal: 1,
        longName: 'Polkadot'
    },
    {
        celer: 'ENJ',
        netdania: 'ENJ',
        show: 'ENJ',
        min_order_size: 200,
        order_decimals: 3,
        increments: 50000,
        type: 'Crypto',
        allowDepositWithdrawal: 1,
        longName: 'Enjin'
    },
    {
        celer: 'EOS',
        netdania: 'EOS',
        show: 'EOS',
        min_order_size: 100,
        order_decimals: 3,
        increments: 10000,
        type: 'Crypto',
        allowDepositWithdrawal: 1,
        longName: 'EOS'
    },
    {
        celer: 'ETH',
        netdania: 'ETH',
        show: 'ETH',
        min_order_size: 0.1,
        order_decimals: 3,
        increments: 10,
        type: 'Crypto',
        allowDepositWithdrawal: 1,
        longName: 'Ethereum'
    },
    {
        celer: 'GLM',
        netdania: 'GLMR',
        show: 'GLMR',
        min_order_size: 300,
        order_decimals: 4,
        increments: 25000,
        type: 'Crypto',
        allowDepositWithdrawal: 0,
        longName: 'Moonbeam'
    },
    {
        celer: 'KSM',
        netdania: 'KSM',
        show: 'KSM',
        min_order_size: 3,
        order_decimals: 4,
        increments: 500,
        type: 'Crypto',
        allowDepositWithdrawal: 0,
        longName: 'Kusama'
    },
    {
        celer: 'LNK',
        netdania: 'LINK',
        show: 'LINK',
        min_order_size: 10,
        order_decimals: 4,
        increments: 1000,
        type: 'Crypto',
        allowDepositWithdrawal: 1,
        longName: 'Chainlink'
    },
    {
        celer: 'LRC',
        netdania: 'LRC',
        show: 'LRC',
        min_order_size: 100,
        order_decimals: 3,
        increments: 50000,
        type: 'Crypto',
        allowDepositWithdrawal: 0,
        longName: 'Loopring'
    },
    {
        celer: 'LTC',
        netdania: 'LTC',
        show: 'LTC',
        min_order_size: 1,
        order_decimals: 4,
        increments: 100,
        type: 'Crypto',
        allowDepositWithdrawal: 1,
        longName: 'Litecoin'
    },
    {
        celer: 'MAN',
        netdania: 'MANA',
        show: 'MANA',
        min_order_size: 100,
        order_decimals: 3,
        increments: 25000,
        type: 'Crypto',
        allowDepositWithdrawal: 1,
        longName: 'Decentraland'
    },
    {
        celer: 'MAT',
        netdania: 'MATIC',
        show: 'MATIC',
        min_order_size: 100,
        order_decimals: 3,
        increments: 10000,
        type: 'Crypto',
        allowDepositWithdrawal: 1,
        longName: 'Polygon'
    },
    {
        celer: 'MKR',
        netdania: 'MKR',
        show: 'MKR',
        min_order_size: 0.1,
        order_decimals: 6,
        increments: 20,
        type: 'Crypto',
        allowDepositWithdrawal: 1,
        longName: 'Maker'
    },
    {
        celer: 'NER',
        netdania: 'NEAR',
        show: 'NEAR',
        min_order_size: 50,
        order_decimals: 4,
        increments: 10000,
        type: 'Crypto',
        allowDepositWithdrawal: 0,
        longName: 'Near'
    },
    {
        celer: 'OMG',
        netdania: 'OMG',
        show: 'OMG',
        min_order_size: 50,
        order_decimals: 3,
        increments: 10000,
        type: 'Crypto',
        allowDepositWithdrawal: 1,
        longName: 'OMG Network '
    },
    {
        celer: 'SHB',
        netdania: 'SHIB',
        show: 'SHIB',
        min_order_size: 1000000,
        order_decimals: 0,
        increments: 500000,
        type: 'Crypto',
        allowDepositWithdrawal: 1,
        longName: 'Shiba Inu'
    },
    {
        celer: 'SND',
        netdania: 'SAND',
        show: 'SAND',
        min_order_size: 100,
        order_decimals: 2,
        increments: 20000,
        type: 'Crypto',
        allowDepositWithdrawal: 1,
        longName: 'The Sandbox'
    },
    {
        celer: 'SOL',
        netdania: 'SOL',
        show: 'SOL',
        min_order_size: 5,
        order_decimals: 4,
        increments: 1000,
        type: 'Crypto',
        allowDepositWithdrawal: 1,
        longName: 'Solana'
    },
    {
        celer: 'SUS',
        netdania: 'SUSHI',
        show: 'SUSHI',
        min_order_size: 100,
        order_decimals: 4,
        increments: 10000,
        type: 'Crypto',
        allowDepositWithdrawal: 1,
        longName: 'SushiSwap'
    },
    {
        celer: 'TRX',
        netdania: 'TRX',
        show: 'TRX',
        min_order_size: 1000,
        order_decimals: 4,
        increments: 200000,
        type: 'Crypto',
        allowDepositWithdrawal: 0,
        longName: 'Tron'
    },
    {
        celer: 'UNI',
        netdania: 'UNI',
        show: 'UNI',
        min_order_size: 10,
        order_decimals: 4,
        increments: 2000,
        type: 'Crypto',
        allowDepositWithdrawal: 1,
        longName: 'Uniswap'
    },
    {
        celer: 'USC',
        netdania: 'USDC',
        show: 'USDC',
        min_order_size: 100,
        order_decimals: 3,
        increments: 10000,
        type: 'Crypto',
        allowDepositWithdrawal: 1,
        longName: 'USD Coin'
    },
    {
        celer: 'UST',
        netdania: 'USDT',
        show: 'USDT',
        min_order_size: 100,
        order_decimals: 3,
        increments: 10000,
        type: 'Crypto',
        allowDepositWithdrawal: 1,
        longName: 'Tether'
    },
    {
        celer: 'XLM',
        netdania: 'XLM',
        show: 'XLM',
        min_order_size: 1000,
        order_decimals: 2,
        increments: 200000,
        type: 'Crypto',
        allowDepositWithdrawal: 0,
        longName: 'Stellar'
    },
    {
        celer: 'XRP',
        netdania: 'XRP',
        show: 'XRP',
        min_order_size: 100,
        order_decimals: 3,
        increments: 25000,
        type: 'Crypto',
        allowDepositWithdrawal: 1,
        longName: 'Ripple'
    },
    {
        celer: 'XTZ',
        netdania: 'XTZ',
        show: 'XTZ',
        min_order_size: 100,
        order_decimals: 3,
        increments: 10000,
        type: 'Crypto',
        allowDepositWithdrawal: 1,
        longName: 'Tezos'
    },
    {
        celer: 'AED',
        netdania: 'AED',
        show: 'AED',
        min_order_size: 1000,
        order_decimals: 2,
        increments: 100000,
        type: 'Fiat',
        allowDepositWithdrawal: 1,
        longName: 'AED'
    },
    {
        celer: 'AUD',
        netdania: 'AUD',
        show: 'AUD',
        min_order_size: 1000,
        order_decimals: 2,
        increments: 100000,
        type: 'Fiat',
        allowDepositWithdrawal: 1,
        longName: 'Australian dollar'
    },
    {
        celer: 'CAD',
        netdania: 'CAD',
        show: 'CAD',
        min_order_size: 1000,
        order_decimals: 2,
        increments: 100000,
        type: 'Fiat',
        allowDepositWithdrawal: 0,
        longName: 'Canadian dollar'
    },
    {
        celer: 'CHF',
        netdania: 'CHF',
        show: 'CHF',
        min_order_size: 1000,
        order_decimals: 2,
        increments: 100000,
        type: 'Fiat',
        allowDepositWithdrawal: 1,
        longName: 'Swiss franc'
    },
    {
        celer: 'CNH',
        netdania: 'CNH',
        show: 'CNH',
        min_order_size: 10000,
        order_decimals: 2,
        increments: 1000000,
        type: 'Fiat',
        allowDepositWithdrawal: 0,
        longName: 'Chinese Yuan (offshore)'
    },
    {
        celer: 'CZK',
        netdania: 'CZK',
        show: 'CZK',
        min_order_size: 20000,
        order_decimals: 2,
        increments: 1000000,
        type: 'Fiat',
        allowDepositWithdrawal: 0,
        longName: 'Czech koruna'
    },
    {
        celer: 'DKK',
        netdania: 'DKK',
        show: 'DKK',
        min_order_size: 10000,
        order_decimals: 2,
        increments: 1000000,
        type: 'Fiat',
        allowDepositWithdrawal: 1,
        longName: 'Danish krone'
    },
    {
        celer: 'EUR',
        netdania: 'EUR',
        show: 'EUR',
        min_order_size: 1000,
        order_decimals: 2,
        increments: 100000,
        type: 'Fiat',
        allowDepositWithdrawal: 1,
        longName: 'Euro'
    },
    {
        celer: 'GBP',
        netdania: 'GBP',
        show: 'GBP',
        min_order_size: 1000,
        order_decimals: 2,
        increments: 100000,
        type: 'Fiat',
        allowDepositWithdrawal: 1,
        longName: 'British Pounds'
    },
    {
        celer: 'HKD',
        netdania: 'HKD',
        show: 'HKD',
        min_order_size: 10000,
        order_decimals: 2,
        increments: 1000000,
        type: 'Fiat',
        allowDepositWithdrawal: 1,
        longName: 'Hong Kong Dollar'
    },
    {
        celer: 'HUF',
        netdania: 'HUF',
        show: 'HUF',
        min_order_size: 300000,
        order_decimals: 0,
        increments: 1000000,
        type: 'Fiat',
        allowDepositWithdrawal: 0,
        longName: 'Hungarian Forint'
    },
    {
        celer: 'ILS',
        netdania: 'ILS',
        show: 'ILS',
        min_order_size: 5000,
        order_decimals: 2,
        increments: 100000,
        type: 'Fiat',
        allowDepositWithdrawal: 0,
        longName: 'Israeli Shekel'
    },
    {
        celer: 'JPY',
        netdania: 'JPY',
        show: 'JPY',
        min_order_size: 100000,
        order_decimals: 0,
        increments: 1000000,
        type: 'Fiat',
        allowDepositWithdrawal: 1,
        longName: 'Japanese Yen'
    },
    {
        celer: 'MXN',
        netdania: 'MXN',
        show: 'MXN',
        min_order_size: 20000,
        order_decimals: 2,
        increments: 1000000,
        type: 'Fiat',
        allowDepositWithdrawal: 0,
        longName: 'Mexican Peso'
    },
    {
        celer: 'NOK',
        netdania: 'NOK',
        show: 'NOK',
        min_order_size: 10000,
        order_decimals: 2,
        increments: 1000000,
        type: 'Fiat',
        allowDepositWithdrawal: 0,
        longName: 'Norwegian Krone'
    },
    {
        celer: 'NZD',
        netdania: 'NZD',
        show: 'NZD',
        min_order_size: 1000,
        order_decimals: 2,
        increments: 100000,
        type: 'Fiat',
        allowDepositWithdrawal: 0,
        longName: 'New Zealand Dollar'
    },
    {
        celer: 'PLN',
        netdania: 'PLN',
        show: 'PLN',
        min_order_size: 5000,
        order_decimals: 2,
        increments: 100000,
        type: 'Fiat',
        allowDepositWithdrawal: 0,
        longName: 'Polish Zloty'
    },
    {
        celer: 'SEK',
        netdania: 'SEK',
        show: 'SEK',
        min_order_size: 10000,
        order_decimals: 2,
        increments: 1000000,
        type: 'Fiat',
        allowDepositWithdrawal: 0,
        longName: 'Swedish Krona'
    },
    {
        celer: 'SGD',
        netdania: 'SGD',
        show: 'SGD',
        min_order_size: 1000,
        order_decimals: 2,
        increments: 100000,
        type: 'Fiat',
        allowDepositWithdrawal: 0,
        longName: 'Singapore dollar'
    },
    {
        celer: 'TRY',
        netdania: 'TRY',
        show: 'TRY',
        min_order_size: 20000,
        order_decimals: 0,
        increments: 1000000,
        type: 'Fiat',
        allowDepositWithdrawal: 1,
        longName: 'Turkish lira'
    },
    {
        celer: 'USD',
        netdania: 'USD',
        show: 'USD',
        min_order_size: 100,
        order_decimals: 2,
        increments: 100000,
        type: 'Fiat',
        allowDepositWithdrawal: 1,
        longName: 'US Dollar'
    },
    {
        celer: 'XAG',
        netdania: 'XAG',
        show: 'XAG',
        min_order_size: 50,
        order_decimals: 1,
        increments: 500,
        type: 'Fiat',
        allowDepositWithdrawal: 0,
        longName: 'Silver (ounce)'
    },
    {
        celer: 'XAU',
        netdania: 'XAU',
        show: 'XAU',
        min_order_size: 0.5,
        order_decimals: 1,
        increments: 10,
        type: 'Fiat',
        allowDepositWithdrawal: 0,
        longName: 'Gold (ounce)'
    },
    {
        celer: 'XPD',
        netdania: 'XPD',
        show: 'XPD',
        min_order_size: 1,
        order_decimals: 1,
        increments: 10,
        type: 'Fiat',
        allowDepositWithdrawal: 0,
        longName: 'Palladium (ounce)'
    },
    {
        celer: 'XPT',
        netdania: 'XPT',
        show: 'XPT',
        min_order_size: 1,
        order_decimals: 1,
        increments: 10,
        type: 'Fiat',
        allowDepositWithdrawal: 0,
        longName: 'Platinum (ounce)'
    },
    {
        celer: 'ZAR',
        netdania: 'ZAR',
        show: 'ZAR',
        min_order_size: 20000,
        order_decimals: 2,
        increments: 1000000,
        type: 'Fiat',
        allowDepositWithdrawal: 0,
        longName: 'South African Rand'
    },
    {
        celer: 'EUC',
        netdania: 'EURC',
        show: 'EURC',
        min_order_size: 1000,
        order_decimals: 2,
        increments: 100000,
        type: 'Crypto',
        allowDepositWithdrawal: 0,
        longName: 'Euro Coin'
    },
    {
        celer: 'AXI',
        netdania: 'AXI',
        show: 'AXI',
        min_order_size: 0.01,
        order_decimals: 2,
        increments: 0.5,
        type: 'Index',
        allowDepositWithdrawal: 0,
        longName: 'ASX 200'
    },
    {
        celer: 'STX',
        netdania: 'STX',
        show: 'STX',
        min_order_size: 0.01,
        order_decimals: 2,
        increments: 0.5,
        type: 'Index',
        allowDepositWithdrawal: 0,
        longName: 'Eurostoxx 50'
    },
    {
        celer: 'FRI',
        netdania: 'FRI',
        show: 'FRI',
        min_order_size: 0.01,
        order_decimals: 2,
        increments: 0.5,
        type: 'Index',
        allowDepositWithdrawal: 0,
        longName: 'CAC 40'
    },
    {
        celer: 'GEM',
        netdania: 'GEM',
        show: 'GEM',
        min_order_size: 0.01,
        order_decimals: 2,
        increments: 0.5,
        type: 'Index',
        allowDepositWithdrawal: 0,
        longName: 'DAX 30'
    },
    {
        celer: 'UKI',
        netdania: 'UKI',
        show: 'UKI',
        min_order_size: 0.01,
        order_decimals: 2,
        increments: 0.5,
        type: 'Index',
        allowDepositWithdrawal: 0,
        longName: 'FTSE 100'
    },
    {
        celer: 'HSI',
        netdania: 'HSI',
        show: 'HSI',
        min_order_size: 0.01,
        order_decimals: 2,
        increments: 0.5,
        type: 'Index',
        allowDepositWithdrawal: 0,
        longName: 'Hang Seng'
    },
    {
        celer: 'JPU',
        netdania: 'JPU',
        show: 'JPU',
        min_order_size: 1,
        order_decimals: 2,
        increments: 0.5,
        type: 'Index',
        allowDepositWithdrawal: 0,
        longName: 'Nikkei 222'
    },
    {
        celer: 'SGP',
        netdania: 'SGP',
        show: 'SGP',
        min_order_size: 0.01,
        order_decimals: 2,
        increments: 0.5,
        type: 'Index',
        allowDepositWithdrawal: 0,
        longName: 'Singapore 20'
    },
    {
        celer: 'DJI',
        netdania: 'DJI',
        show: 'DJI',
        min_order_size: 0.01,
        order_decimals: 2,
        increments: 0.5,
        type: 'Index',
        allowDepositWithdrawal: 0,
        longName: 'Dow Jones 30'
    },
    {
        celer: 'SPM',
        netdania: 'SPM',
        show: 'SPM',
        min_order_size: 0.01,
        order_decimals: 2,
        increments: 0.5,
        type: 'Index',
        allowDepositWithdrawal: 0,
        longName: 'S&P 500'
    },
    {
        celer: 'NDM',
        netdania: 'NDM',
        show: 'NDM',
        min_order_size: 0.01,
        order_decimals: 2,
        increments: 0.5,
        type: 'Index',
        allowDepositWithdrawal: 0,
        longName: 'Nasdaq 100'
    },
    {
        celer: 'CNI',
        netdania: 'CNI',
        show: 'CNI',
        min_order_size: 0.01,
        order_decimals: 2,
        increments: 0.5,
        type: 'Index',
        allowDepositWithdrawal: 0,
        longName: 'China A50'
    },
    {
        celer: 'RSI',
        netdania: 'RSI',
        show: 'RSI',
        min_order_size: 0.01,
        order_decimals: 2,
        increments: 0.5,
        type: 'Index',
        allowDepositWithdrawal: 0,
        longName: 'Russel 200'
    },
    {
        celer: 'SPL',
        netdania: 'SPL',
        show: 'SPL',
        min_order_size: 0.01,
        order_decimals: 2,
        increments: 0.5,
        type: 'Index',
        allowDepositWithdrawal: 0,
        longName: 'S&P mid cap 400'
    },
    {
        celer: 'TWN',
        netdania: 'TWN',
        show: 'TWN',
        min_order_size: 0.01,
        order_decimals: 2,
        increments: 0.5,
        type: 'Index',
        allowDepositWithdrawal: 0,
        longName: 'Taiwan Index'
    },
    {
        celer: 'UKO',
        netdania: 'UKO',
        show: 'UKO',
        min_order_size: 0.01,
        order_decimals: 2,
        increments: 0.5,
        type: 'Commodity',
        allowDepositWithdrawal: 0,
        longName: 'UK Brent Oil'
    },
    {
        celer: 'USO',
        netdania: 'USO',
        show: 'USO',
        min_order_size: 0.01,
        order_decimals: 2,
        increments: 0.5,
        type: 'Commodity',
        allowDepositWithdrawal: 0,
        longName: 'US Oil'
    },
    {
        celer: 'GRT',
        netdania: 'GRT',
        show: 'GRT',
        min_order_size: 100,
        order_decimals: 0,
        increments: 1000,
        type: 'Crypto',
        allowDepositWithdrawal: 0,
        longName: 'The Graph'
    },
    {
        celer: 'GAL',
        netdania: 'GALA',
        show: 'GALA',
        min_order_size: 100,
        order_decimals: 0,
        increments: 1000,
        type: 'Crypto',
        allowDepositWithdrawal: 0,
        longName: 'Galxe'
    },
    {
        celer: 'FTM',
        netdania: 'FTM',
        show: 'FTM',
        min_order_size: 100,
        order_decimals: 0,
        increments: 1000,
        type: 'Crypto',
        allowDepositWithdrawal: 0,
        longName: 'Fantom'
    },
    {
        celer: 'APE',
        netdania: 'APE',
        show: 'APE',
        min_order_size: 100,
        order_decimals: 0,
        increments: 1000,
        type: 'Crypto',
        allowDepositWithdrawal: 0,
        longName: 'ApeCoin'
    },
    {
        celer: 'ALG',
        netdania: 'ALGO',
        show: 'ALGO',
        min_order_size: 100,
        order_decimals: 0,
        increments: 1000,
        type: 'Crypto',
        allowDepositWithdrawal: 0,
        longName: 'Algory Project'
    },
    {
        celer: 'USI',
        netdania: 'USI',
        show: 'USI',
        min_order_size: 0.1,
        order_decimals: 1,
        increments: 0.5,
        type: 'Index',
        allowDepositWithdrawal: 0,
        longName: 'USI'
    },
    {
        celer: 'WBC',
        netdania: 'WBTC',
        show: 'WBTC',
        min_order_size: 0.001,
        order_decimals: 3,
        increments: 0.5,
        type: 'Crypto',
        allowDepositWithdrawal: 0,
        longName: 'Wrapped Bitcoin'
    },
    {
        celer: 'GAS',
        netdania: 'GAS',
        show: 'GAS',
        min_order_size: 0.01,
        order_decimals: 2,
        increments: 0.5,
        type: 'Commodity',
        allowDepositWithdrawal: 0,
        longName: 'Gas'
    },
    {
        celer: 'VIX',
        netdania: 'VIX',
        show: 'VIX',
        min_order_size: 0.01,
        order_decimals: 2,
        increments: 0.5,
        type: 'Index',
        allowDepositWithdrawal: 0,
        longName: 'Vixco'
    }
];

export const currencyConfigDict: CurrencyConfigDict = currencyConfig.reduce((acc, curr) => {
    acc[curr.celer] = curr;
    return acc;
}, {});

export default currencyConfig;
