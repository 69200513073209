import { useAppDispatch, useAppSelector } from '@/state/hooks';
import { selectTradingSettings, setTradingLayout } from '@/state/reducers/settingSlice';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Loader from '../common/Loader';
import Modal from './Modal';

interface RefreshVersionModalProps {
    opened: boolean;
    handlers: {
        open: () => void;
        close: () => void;
        toggle: () => void;
    };
}

const expirationDuration = +(window.config?.refreshVersionInterval || 1500);

const defaultTradingLayout = window.config?.layout?.default;
const xplorspotEnabled = window.config?.layout?.xplorspot?.enabled;
const xplorspotliteEnabled = window.config?.layout?.xplorspotlite?.enabled;
const xplortraderEnabled = window.config?.layout?.xplortrader?.enabled;

const RefreshVersionModal = (props: RefreshVersionModalProps) => {
    const { opened, handlers } = props;
    const dispatch = useAppDispatch();
    const tradingSettings = useAppSelector(selectTradingSettings);

    const navigate = useNavigate();

    const [expirationTimer, setExpirationTimer] = useState<number>(expirationDuration);

    useEffect(() => {
        if (opened) setExpirationTimer(expirationDuration);
    }, [opened]);

    useEffect(() => {
        if (opened) {
            const timer = setTimeout(() => setExpirationTimer(expirationTimer - 1000), 1000);
            if (expirationTimer <= 0) {
                clearTimeout(timer);

                const tradingLayout = tradingSettings.tradingLayout?.value;

                if (tradingLayout === 'xplorspotlite' && xplorspotliteEnabled) {
                    navigate('/lite');
                } else if (tradingLayout === 'xplortrader' && xplortraderEnabled) {
                    navigate('/trader');
                } else if (tradingLayout === 'xplorspot' && xplorspotEnabled) {
                    navigate('/');
                } else {
                    let path = '/';
                    if (defaultTradingLayout === 'xplorspotlite') path = '/lite';
                    if (defaultTradingLayout === 'xplortrader') path = '/trader';
                    dispatch(setTradingLayout(defaultTradingLayout));
                    navigate(path);
                }

                location.reload();
            }
        }
    }, [opened, expirationTimer]);

    return (
        <Modal {...props} className="h-auto" overlayOpacity="bg-opacity-100">
            <div className="flex items-center gap-3 text-neutral-200 p-2 sm:p-4">
                <Loader className="w-5 h-5 mr-0 ml-0 text-brand-primary-light" />
                <div>Updating to the latest XplorSpot...</div>
            </div>
        </Modal>
    );
};

export default RefreshVersionModal;
