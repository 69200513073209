import cn from 'classnames';
import React from 'react';
import { FieldError } from 'react-hook-form';
import ErrorMessage from './ErrorMessage';

export type FormInputWrapperProps = {
    label?: string | JSX.Element;
    description?: string;
    error?: FieldError;
    required?: boolean;
    alignStart?: boolean;
    labelClassName?: string;
    inputInfo?: JSX.Element;
    children: JSX.Element;
};

export const FormInputWrapper: React.FC<FormInputWrapperProps> = ({
    children,
    description = '',
    error,
    label = '',
    required = false,
    alignStart = false,
    labelClassName,
    inputInfo
}) => {
    return (
        <div className="w-full">
            <div
                className={cn('flex flex-col sm:flex-row gap-2 md:gap-4 justify-between text-neutral-200 w-full', {
                    ['items-center']: !alignStart,
                    ['items-start']: alignStart
                })}>
                {label ? (
                    <div
                        className={cn('flex flex-row items-center w-full sm:w-auto flex-none', {
                            ['py-1.5']: alignStart
                        })}>
                        <div className={cn('flex flex-col min-w-60', labelClassName)}>
                            <label className="flex justify-between relative text-sm w-full">
                                <div
                                    className={cn('', {
                                        'pl-3': required
                                    })}>
                                    {required && (
                                        <sup className="absolute -top-1 left-0 text-lg mr-1.5 text-brand-red-light">
                                            *
                                        </sup>
                                    )}
                                    {label}
                                </div>
                            </label>
                            {description ? (
                                <div className="text-xs text-neutral-500 w-full white max-w-60 pr-2">{description}</div>
                            ) : null}
                        </div>
                        <div className="flex self-start">
                            <span className="hidden sm:block space-x-1.5">
                                <span>:</span>
                            </span>
                        </div>
                    </div>
                ) : null}

                <div className="w-full sm:w-auto sm:flex-1 sm:basis-0">
                    {children}
                    {inputInfo ? inputInfo : null}
                </div>
            </div>
            <ErrorMessage error={error} />
        </div>
    );
};
